import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ProductsService } from "../../products/product.service";

@Component({
	selector: "kt-view-product-pricing",
	templateUrl: "./view-product-pricing.component.html",
	styleUrls: ["./view-product-pricing.component.scss"],
})
export class ViewProductPricingComponent implements OnInit {
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;

	ordersList$: Observable<any[]>;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	order_no: String;
	order_date: String;
	order_id: String;
	orderValue: String;
	company_name: String;
	order_status: String;
	loading = false;
	constructor(public dialogRef: MatDialogRef<ViewProductPricingComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private _service: ProductsService, private spinner: NgxSpinnerService) {
		const ProductsPricingId = this.data.ProductsPricingId;
		this.ordersList$ = this.ordersListSubject.asObservable();

		this.OrdersDetailsGET(ProductsPricingId);
		console.log("Received Order ID:", ProductsPricingId);
	}

	OrdersDetailsGET(ProductsPricingId) {
		this.spinner.show();
		this._service.Products_pricing_detailsGET(ProductsPricingId).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.spinner.hide();
			// this.orderValue = response[0].order_value;
			// this.order_no = response[0].order_no;
			// this.order_date = response[0].order_date;
			// this.company_name = response[0].company_name;
			// this.order_status = response[0].order_status;
		});
	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.customerForm = this.fb.group({
			firstName: [" ", Validators.required],
			lastName: ["", Validators.required],
			email: ["", Validators.compose([Validators.required, Validators.email])],
			dob: ["", Validators.compose([Validators.nullValidator])],
			userName: ["", Validators.compose([Validators.required])],
			gender: ["", Validators.compose([Validators.required])],
			ipAddress: ["", Validators.compose([Validators.required])],
			type: ["", Validators.compose([Validators.required])],
		});
	}
	getTitle(): string {
		return "View Product Pricing";
	}
}
