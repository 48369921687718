import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormBuilder, FormGroup, Validators, FormArray, FormGroupName } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router } from "@angular/router";
import { BaseDataSource, QueryParamsModel, LayoutUtilsService, MessageType } from "../../core/_base/crud";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogConfig, MatSort, MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";
import { Action, Store } from "@ngrx/store";
import { AppState } from "../../core/reducers";
import { MasterModel } from "../../views/pages/masters/masters.model";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { CoffeeService } from "../coffee.service";
import { SiteService } from "../site.service";
const MASTER_DATA: MasterModel[] = [];

@Component({
	selector: "kt-sites-data",
	templateUrl: "./sites-data.component.html",
	styleUrls: ["./sites-data.component.scss"],
})
export class SitesDataComponent implements OnInit {
	displayedColumns: string[] = ["MasterName", "SubMasterName", "actions"];
	dataSource = new MatTableDataSource<MasterModel>(MASTER_DATA);
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	public master: MasterModel;
	showSpinner = false;
	public hasItems: boolean;
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 2;
	MasterId: number = 0;
	public employeeId: string = "0";
	totalLengthEmitter$ = new BehaviorSubject<number>(this.totalLength);

	masters: [];

	observable: Observable<[]>;
	loading2 = false;
	submitted = false;
	applyLeaveId: number = 0;
	masterList$: Observable<any>;
	tempmasterList$: Observable<any>;
	masterDropDown$: Observable<any>;
	empstatusfrm: FormGroup;
	employeeStatusFormShow = false;
	@Output() messageEvent = new EventEmitter<string>();

	usersList$: Observable<any[]>;
	private usersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	searchQuery: string = "";
	totalPages: number = 50; // Replace with your actual total number of pages
	currentPage: number = 1; // Replace with your actual current page
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	constructor(private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private spinner: NgxSpinnerService, private _router: Router, private dialog: MatDialog, private store: Store<AppState>, public snackBar: MatSnackBar, private layoutUtilsService: LayoutUtilsService, private ref: ChangeDetectorRef, private siteSerive: SiteService) {
		this.usersList$ = this.usersListSubject.asObservable();
	}
	onPageChange(page) {
		this.currentPage = page;
		//this.itemsPerPage = 10;
		this.getSite();
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1; // Reset to the first page
		this.getSite();
	}
	getSite() {
		const startIndex = this.currentPage; // Set your desired values for the parameters
		const pageSize = this.itemsPerPage;
		const searchBy = this.searchQuery ? "1" : "0";
		const searchCriteria = this.searchQuery;
		this.spinner.show();
		this.siteSerive.OwnsersGET(startIndex, pageSize, searchBy, searchCriteria).subscribe((response: any[]) => {
			this.usersListSubject.next(response);
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.spinner.hide();
			this.ref.markForCheck();
			this.ref.detectChanges();
		});
	}
	onPageChanged(e) {
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		this.getSite();
	}
	onPageSizeChange(newSize: number) {
		this.itemsPerPage = newSize;
		this.getSite();
		this.ref.detectChanges();
	}

	editUserData(userInfo: string) {
		localStorage.setItem("siteStorage", JSON.stringify(userInfo));
		this._router.navigate(["/coffee/sitemaster"]);
	}
	ngOnInit() {
		this.getSite();
	}
}
