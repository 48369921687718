import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ProductsService } from "../product.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { SharedDataService } from "../../shared-data.service";
import { debounceTime, distinctUntilChanged, startWith, switchMap } from "rxjs/operators";

@Component({
	selector: "kt-product",
	templateUrl: "./product.component.html",
	styleUrls: ["./product.component.scss"],
})
export class IniProductComponent implements OnInit {
	@ViewChild("wizard", { static: true }) el: ElementRef;

	selectedFiles: FileList | null;
	filePreviews: { file: File; progress: number }[] = [];

	images: Array<{ src: string; name: string; ProudctImageId: string; file: File }> = [];
	imageForm: FormGroup;
	selectedValue: any;
	step1ProductInfoForm: FormGroup;
	step2MetaDataInfoForm: FormGroup;
	step3ImagesForm: FormGroup;
	loading = false;
	selectOptionsConfig: any = {};

	currentStep = 1;
	private subscription: Subscription;

	searchCategory: string;
	CategoryData$: Observable<any[]>;

	constructor(private formBuilder: FormBuilder, private sharedDataService: SharedDataService,private _router: Router, private _service: ProductsService, private _snackBar: MatSnackBar, private cd: ChangeDetectorRef, private router: Router) {}

	ngOnInit() {
		this.forms();
		this.setupSearch();
	}

	ngAfterViewInit(): void {
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
		});

		wizard.on("beforeNext", (wizardObj) => {
			const currentStepIndex = wizard.getStep();

			if (!this.validateStep(currentStepIndex)) {
				this._snackBar.open("Please fill in all required fields.", "OK", {
					duration: 3000,
				});
				wizardObj.stop();
			} else {
				this.currentStep++; // Move to the next step
			}
		});

		// Change event
		wizard.on("change", () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});

		// Get the current step index
	}

	forms() {
		this.step1ProductInfoForm = this.formBuilder.group({
			ProductId: ["0"],
			ProductName: ["", Validators.required],
			ShortDescription: ["", Validators.required],
			Description: ["", Validators.required],
			ImsMappingId: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
			CategoryID: ["", Validators.required],
			Sku: ["", Validators.required],
			DefaultPrice: ["", Validators.required],
			ShowWebSite: [true],
			UserId: ["0"],
			IsActive:[true],
		});

		this.step2MetaDataInfoForm = this.formBuilder.group({
			MetaTitle: [""],
			MetaKeywords: [""],
			MetaDescription: [""],
		});

		this.imageForm = this.formBuilder.group({
			owner_company_id: ["0"],
			user_id: ["0"],
		});

		this.subscription = this.sharedDataService.productData$.subscribe((data) => {
			var obj = JSON.parse(data);

			this.step1ProductInfoForm.controls.ProductId.setValue(obj.ProductId.toString());
			this.step1ProductInfoForm.controls.ProductName.setValue(obj.ProductName.toString());
			this.step1ProductInfoForm.controls.ShortDescription.setValue(obj.ShortDescription);
			this.step1ProductInfoForm.controls.Description.setValue(obj.Description);

			this.step1ProductInfoForm.controls.ImsMappingId.setValue(obj.ImsMappingId.toString());
			this.step1ProductInfoForm.controls.CategoryID.setValue(obj.CategoryID.toString());
			this.step1ProductInfoForm.controls.Sku.setValue(obj.SKU.toString());

			this.step1ProductInfoForm.controls.DefaultPrice.setValue(obj.DefaultPrice);
			this.step1ProductInfoForm.controls.ShowWebSite.setValue(obj.ShowWebSite);

			this.step2MetaDataInfoForm.controls.MetaTitle.setValue(obj.MetaTitle);
			this.step2MetaDataInfoForm.controls.MetaKeywords.setValue(obj.MetaKeywords);
			this.step2MetaDataInfoForm.controls.MetaDescription.setValue(obj.MetaDescription);
			this.step1ProductInfoForm.controls.IsActive.setValue(obj.IsActive);

			this._service.ProductsImagesGetById(obj.ProductId.toString()).subscribe(
				(data: any[]) => {
					this.images = data.map((item) => ({
						src: environment.BaseUrl + item.ImageUrl,
						ProudctImageId: item.ProudctImageId.toString(),
						name: item.filenames.toString(),
						file: null,
					}));
				},
				(error) => {
					console.error(error);
				}
			);
		});

		this.sharedDataService.clearProductData();
	}

	onSubmitPrimaryInfo() {
		this.loading = true;
		if (this.step1ProductInfoForm.invalid) {
			Object.values(this.step1ProductInfoForm.controls).forEach((control) => control.markAsTouched());
			this.loading = false;
			return;
		}

		const formData = new FormData();
		formData.append("data1", JSON.stringify(this.step1ProductInfoForm.value));
		formData.append("data2", JSON.stringify(this.step2MetaDataInfoForm.value));

		for (const image of this.images) {
			formData.append("files", image.file);
		}

		this._service.productsave(formData).subscribe(
			(res: any) => {
				console.log(res);
				this._snackBar.open("Saved Successfully", "Undo", {
					duration: 3000,
				});
				this._router.navigate(["/coffee/products-data-page"]);
			},
			(error: any) => {
				this.loading = false;
				this._snackBar.open(error.error, "Undo", {
					duration: 3000,
				});
			}
		);
	}
	onValueChanged(event: any) {
		this.selectedValue = event;
		console.log("Selected Value:", this.selectedValue);
	}

	onFileSelected(event: any) {
		const files = event.target.files;
		if (files.length > 5) {
			alert("You can only upload up to five images.");
			return;
		}
		this.processFiles(files);
	}

	processFiles(files: File[]) {
		for (const file of files) {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				this.images.push({ src: e.target.result, ProudctImageId: "0", name: file.name, file: file });
				this.cd.markForCheck();
			};
			reader.readAsDataURL(file);
		}
	}

	removeImage(index: number, ProudctImageId: string, name: string) {
		this.images.splice(index, 1);

		if (ProudctImageId != "0") {
			const ImageConst = {
				ProudctImageId: ProudctImageId,
				ImageUrl: name,
			};
			this._service.ProductsImagesDelete(ImageConst).subscribe(
				(data: any[]) => {
					this.images = data.map((item) => ({
						src: environment.BaseUrl + item.ImageUrl,
						ProudctImageId: item.ProudctImageId.toString(),
						name: item.filenames.toString(),
						file: null,
					}));
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}

	setupSearch() {
		this.CategoryData$ = new Observable<string>((observer) => {
			observer.next(this.searchCategory);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this._service.dropdownAll(query, "1", "5", "0");
			})
		);
	}
	getCategoryConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 5,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Manager",
			minimumInputLength: 0,
		};
	}

	validateStep(step: number): boolean {
		switch (step) {
			case 1: {
				if (this.step1ProductInfoForm.invalid) {
					Object.values(this.step1ProductInfoForm.controls).forEach((control) => control.markAsTouched());
					this.cd.markForCheck();
					this.cd.detectChanges();
					return false;
				} else {
					return true;
				}
			}

			case 2: {
				if (this.step2MetaDataInfoForm.invalid) {
					Object.values(this.step2MetaDataInfoForm.controls).forEach((control) => control.markAsTouched());
					this.cd.markForCheck();
					this.cd.detectChanges();
					return false;
				} else {
					return true;
				}
			}

			case 3: {
				if (this.step3ImagesForm.invalid) {
					Object.values(this.step3ImagesForm.controls).forEach((control) => control.markAsTouched());
					this.cd.markForCheck();
					this.cd.detectChanges();
					return false;
				} else {
					return true;
				}
			}

			default:
				return false;
		}
	}
}
