import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormArray, Validators, FormGroup, AbstractControl, ValidatorFn } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CoffeeService } from "../../coffee.service";
import { SharedDataService } from "../../shared-data.service";

@Component({
	selector: "kt-edit-user",
	templateUrl: "./edit-user.component.html",
	styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
	userForm: FormGroup;
	userPasswordForm: FormGroup;
	dropdownOptions$: Observable<any[]>;
	loading = false;
	private subscription: Subscription;

	constructor(private route: ActivatedRoute, private sharedDataService: SharedDataService, private fb: FormBuilder, private _router: Router, private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private cd: ChangeDetectorRef, private coffeeService: CoffeeService) {}

	roleDropdown() {
		this.dropdownOptions$ = this.coffeeService.roleDropdown();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	onChangePassword() {
		this.loading = true;
		if (this.userPasswordForm.invalid) {
			Object.values(this.userPasswordForm.controls).forEach((control) => control.markAsTouched());

			return;
		}

		const formData = { ...this.userPasswordForm.value };

		this.coffeeService.MasterUsersChangePassword(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
					this._router.navigate(["/coffee/userdata"]);
				}
			},
			(error: any) => {
				this._snackBar.open(error.error, "OK", {
					duration: 3000,
				});
				this.loading = false;
			}
		);
	}

	onSubmit() {
		this.loading = true;
		if (this.userForm.invalid) {
			Object.values(this.userForm.controls).forEach((control) => control.markAsTouched());

			return;
		}

		const formData = { ...this.userForm.value };

		this.coffeeService.saveUsersDetails(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
					this._router.navigate(["/coffee/userdata"]);
				}
			},
			(error: any) => {
				this._snackBar.open(error.error, "OK", {
					duration: 3000,
				});
				this.loading = false;
				///console.error(error); // Optionally log the error to the console for debugging purposes
			}
		);
	}
	ngOnInit() {
		this.roleDropdown();

		this.userForm = this.fb.group({
			userId: ["0"],
			roleId: ["", Validators.required],
			userName: ["", Validators.required],
			firstName: ["", Validators.required],
			middleName: ["", Validators.required],
			lastName: ["", Validators.required],
			emailId: ["", [Validators.required, emailValidator]],
			mobileNo: ["", [Validators.required, phoneValidator]],
			IsActive: [true],
			IsEditMode: [true],
		});

		this.userPasswordForm = this.fb.group(
			{ userId: ["0"], passwords: ["", [Validators.required]], confirmpassword: ["", [Validators.required]] },
			{
				validator: this.passwordMatchValidator,
			}
		);

		this.subscription = this.sharedDataService.userData$.subscribe((data) => {
			var obj = JSON.parse(data);

			this.userForm.controls.userId.setValue(obj.UserId.toString());
			this.userForm.controls.roleId.setValue(obj.RoleId.toString());
			this.userForm.controls.userName.setValue(obj.UserName);
			this.userForm.controls.firstName.setValue(obj.FirstName);
			this.userForm.controls.middleName.setValue(obj.MiddleName);
			this.userForm.controls.lastName.setValue(obj.LastName);
			this.userForm.controls.emailId.setValue(obj.EmailId);
			this.userForm.controls.mobileNo.setValue(obj.MobileNo);
			this.userForm.controls.IsActive.setValue(obj.IsActive);

			this.userPasswordForm.controls.userId.setValue(obj.UserId.toString());
		});
	}

	passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
		const password = control.get("passwords").value;
		const confirmPassword = control.get("confirmpassword").value;

		// check if passwords match
		if (password !== confirmPassword) {
			return { passwordMismatch: true };
		}

		return null; // validation passed
	}
}
function emailValidator(control: AbstractControl): { [key: string]: any } | null {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	const email = control.value;

	if (email && !emailPattern.test(email)) {
		return { invalidEmail: true };
	}

	return null;
}
function phoneValidator(control: AbstractControl): { [key: string]: any } | null {
	// Define a regular expression for a valid mobile phone number
	const phonePattern = /^[0-9]{10}$/; // You can adjust this pattern to match your specific requirements

	const phone = control.value;

	if (phone && !phonePattern.test(phone)) {
		return { invalidPhone: true };
	}

	return null;
}
