import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ProductsService } from "../product.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../../src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { SharedDataService } from "../../shared-data.service";
import { ConfirmationModalComponent } from "../../Modals/confirmation-modal/confirmation-modal.component";

@Component({
	selector: "kt-products-data-page",
	templateUrl: "./products-data-page.component.html",
	styleUrls: ["./products-data-page.component.scss"],
})
export class ProductsDataPageComponent implements OnInit {
	productList$: Observable<any[]>;
	private productListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	checkedItems: { id: any; text: any }[] = [];
	environment = environment;
	reportForm:FormGroup;

	constructor(private formBuilder: FormBuilder, private dialog: MatDialog,private fb: FormBuilder, private sharedDataService: SharedDataService, private _service: ProductsService, private _snackBar: MatSnackBar, private cd: ChangeDetectorRef, private router: Router) {
		this.productList$ = this.productListSubject.asObservable();
	}
	SearchData() {}
	editProduct(product: any) {

		console.log("ProductData>>:",product)
		this.sharedDataService.setProductData(JSON.stringify(product));
		this.router.navigate(["/coffee/products"]);
	}

	ngOnInit() {

		this.reportForm = this.fb.group({
			search: ["", Validators.compose([Validators.required])],

		});

		this.checkedItems = [{ id: "A", text: "All" }];
		this.getProducts(this.checkedItems);
	}

	getProducts(checkedItems: { id: any; text: any }[]) {
		const startIndex = this.currentPage;
		const pageSize = this.itemsPerPage;
		const searchBy = this.searchQuery ? "1" : "0";
		const searchCriteria = this.searchQuery;

		this._service.productsGET(startIndex, pageSize, searchBy, searchCriteria, checkedItems).subscribe((response: any[]) => {
			this.productListSubject.next(response);
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
		});
	}

	resetform() {
		this.reportForm.reset();
		this.searchQuery = "";
		this.currentPage = 1;
		this.checkedItems = [{ id: "A", text: "All" }];
		this.getProducts(this.checkedItems);
	}

	onPageSizeChange(newSize: number) {
		this.itemsPerPage = newSize;
		this.checkedItems = [{ id: "A", text: "All" }];
		this.getProducts(this.checkedItems);
		this.cd.detectChanges();
	}
	editUserData(userInfo: string) {
		localStorage.setItem("usersStorage", JSON.stringify(userInfo));
		this.router.navigate(["/coffee/usermaster"]);
	}

	//Pagination

	onPageChange(page) {
		this.currentPage = page;
		this.checkedItems = [{ id: "A", text: "All" }];
		this.getProducts(this.checkedItems);
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1;
		this.checkedItems = [{ id: "A", text: "All" }];
		this.getProducts(this.checkedItems);
	}

	generateImageUrl(imageName: string): string {
		return `${this.environment.BaseUrl}/productImages/${imageName}`;
	}

	InActiveProduct(ProductId) {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: "Confirmation",
				description: "Are you sure you want to Inactive Product ?",
				waitDescription: "Please wait we are submitting the data",
			},
			width: "440px",
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}
			this.DeleteProduct(ProductId);
		});
	}

	DeleteProduct(ProductId) {
		const formData = {
			TypeId: "2",
			Id: ProductId.toString(),
		};

		this._service.deleteFromTable(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this._snackBar.open("deleted Successfully", "Undo", {
						duration: 3000,
					});
					this.currentPage = 1;
					this.checkedItems = [{ id: "A", text: "All" }];
					this.getProducts(this.checkedItems);
				}
			},
			(error: any) => {
				this._snackBar.open(error.error, "OK", {
					duration: 3000,
				});
			}
		);
	}
}
