import { ChangeDetectorRef, Component, ElementRef, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteService } from '../../site.service';
const DEMO_PARAMS = {
	EMAIL: 'admin@demo.com',
	PASSWORD: 'demo'
};
@Component({
  selector: 'kt-sitemaster',
  templateUrl: './sitemaster.component.html',
  styleUrls: ['./sitemaster.component.scss']
})
export class SitemasterComponent implements OnInit {

  loading = false;
 
  siteMasterForm:FormGroup;

  stateList$: Observable<any>;
  dropdownOptions$: Observable<any[]>;
  CurrentUserId:string;

  selectedOption: any;
  constructor(private fb: FormBuilder,
		private _router: Router,	
		private formBuilder: FormBuilder, private _snackBar: MatSnackBar,
    private siteService: SiteService,
    private router: Router,
		private cd: ChangeDetectorRef) { }


    initLoginForm() {

    
      this.siteMasterForm = this.formBuilder.group({
        owner_id: ['0', Validators.required],
        owner_name: ['', Validators.required],
        emailId: ['', [Validators.required, Validators.email]],
        mobile: ['', Validators.required],
        panno: ['', Validators.required],
        adharno: ['', Validators.required]
      });

      if (localStorage.getItem("siteStorage") != null) {

        debugger;
        //this.stateDropDown();
        //this.companyTypeDropDown();
        var obj = JSON.parse(localStorage.getItem("siteStorage"));

        this.siteMasterForm.controls.siteMasterId.setValue(obj.SiteMasterId);
        this.siteMasterForm.controls.sitename.setValue(obj.SiteName);
        this.siteMasterForm.controls.sitelocation.setValue(obj.SiteLocation);
        this.siteMasterForm.controls.startdate.setValue(obj.StartDate);
        this.siteMasterForm.controls.userAssignedId.setValue(obj.UserAssignedId);
  
        localStorage.removeItem('siteStorage');
      }


      this.userDropdown();
    }

  ngOnInit() {
    this.initLoginForm();
 
  }

  onSubmit() {

    debugger;
    this.loading = true;
    if (this.siteMasterForm.invalid) {
      // Mark all form controls as touched to trigger the display of error messages
      Object.values(this.siteMasterForm.controls).forEach(control => control.markAsTouched());
      this.loading = false;
      return;
    }

    const formData = { ...this.siteMasterForm.value };

    this.siteService.ownserSave(formData).subscribe(
      (res: any) => {
       
        if (res.error == false) {

          this._snackBar.open('Saved Successfully', 'Undo', {
            duration: 3000
          });

        }
        else {
          this._snackBar.open('Saved Successfully', 'Undo', {
            duration: 3000
          });
        }
        this.siteMasterForm.reset();
        this.router.navigate(['/coffee/sitesdata']);
      })
  }
  
  public UserChanged(value: any): void {
    console.log('Selected value is: ', value);
    this.siteMasterForm.patchValue({
      userAssignedId: value,
    });
    this.CurrentUserId = value;
  }
 userDropdown() {
         this.dropdownOptions$ = this.siteService.userDropdown();
  }

}
