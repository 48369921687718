import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class SiteService {
	public isLoading = new BehaviorSubject(false);
	constructor(private http: HttpClient) {}

	getChartData(): Observable<any> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
		const params = new HttpParams().set("fromDate", "2023-01-01").set("toDate", "2023-12-31");

		return this.http.get<any[]>(environment.BaseUrl + "api/site/sitePunchDataSummary", {
			headers: headers,
			params: params,
		});
	}

	ChartOrdersMonths(): Observable<any> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
		const params = new HttpParams().set("startIndex", "1");

		return this.http.get<any[]>(environment.BaseUrl + "api/Reports/ChartOrdersMonths", {
			headers: headers,
			params: params,
		});
	}

	siteGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria);

		return this.http.get<any[]>(environment.BaseUrl + "api/site/siteGet", {
			headers: headers,
			params: params,
		});
	}

	userDropdown(): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});
		return this.http.get<any[]>(environment.BaseUrl + "api/site/userDropdown", {
			headers: headers,
			params: new HttpParams().set("RoleId", "0"),
		});
	}
	saveSite(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/site/sitesave", formData, { headers });
	}
	ownserSave(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Owner/ownerInsertUpdate", formData, { headers });
	}

	OwnsersGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria);

		return this.http.get<any[]>(environment.BaseUrl + "api/Owner/ownsersGET", {
			headers: headers,
			params: params,
		});
	}
}
