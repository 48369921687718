// shared-data.service.ts

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class SharedDataService {
	private userDataSubject = new BehaviorSubject<any>(null);
	private companyOwnerDataSubject = new BehaviorSubject<any>(null);
	userData$ = this.userDataSubject.asObservable();
	companyOwnerData$ = this.companyOwnerDataSubject.asObservable();

	private productataSubject = new BehaviorSubject<any>(null);
	productData$ = this.productataSubject.asObservable();

	private dispatchPlanDataSubject = new BehaviorSubject<any>(null);
	dispatchPlanData$ = this.dispatchPlanDataSubject.asObservable();

	setUserData(userData: any) {
		this.userDataSubject.next(userData);
	}
	setCompanyOwnerData(companyOwnerData: any) {
		this.companyOwnerDataSubject.next(companyOwnerData);
	}

	setProductData(productData: any) {
		this.productataSubject.next(productData);
	}

	clearCompanyOwnerData() {
		this.companyOwnerDataSubject.next(null);
	}
	clearProductData() {
		this.productataSubject.next(null);
	}

	//Dispatch Plan
	setDispatchPlanData(dispatchPlandata: any) {
		this.dispatchPlanDataSubject.next(dispatchPlandata);
	}
	clearDispatchPlanData() {
		this.dispatchPlanDataSubject.next(null);
	}
}
