import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-coffee',
  templateUrl: './coffee.component.html',
  styleUrls: ['./coffee.component.scss']
})
export class CoffeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
