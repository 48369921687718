import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormBuilder, FormGroup, Validators, FormArray, FormGroupName } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router } from "@angular/router";
import { BaseDataSource, QueryParamsModel, LayoutUtilsService, MessageType } from "../../../../core/_base/crud";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogConfig, MatSort, MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";
import { Action, Store } from "@ngrx/store";

import { Observable, Subject, BehaviorSubject } from "rxjs";
import { CoffeeService } from "../../../coffee.service";
import { ViewDispatchComponent } from "../../../Modals/view-dispatch/view-dispatch.component";
import { debounceTime, distinctUntilChanged, startWith, switchMap } from "rxjs/operators";
import { environment } from "../../../../../../src/environments/environment";
import { DatePipe } from "@angular/common";
import { SharedDataService } from "../../../shared-data.service";

@Component({
	selector: "kt-dispatch-plan-data",
	templateUrl: "./dispatch-plan-data.component.html",
	styleUrls: ["./dispatch-plan-data.component.scss"],
})
export class DispatchPlanDataComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;

	showSpinner = false;
	public hasItems: boolean;
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	MasterId: number = 0;
	public employeeId: string = "0";
	totalLengthEmitter$ = new BehaviorSubject<number>(this.totalLength);

	masters: [];

	observable: Observable<[]>;
	loading2 = false;
	submitted = false;
	applyLeaveId: number = 0;
	masterList$: Observable<any>;
	tempmasterList$: Observable<any>;
	masterDropDown$: Observable<any>;
	empstatusfrm: FormGroup;
	employeeStatusFormShow = false;
	@Output() messageEvent = new EventEmitter<string>();

	dispatchplanList$: Observable<any[]>;
	private dispatchplanListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";
	loading = false;
	loadingSubmit = false;

	reportForm: FormGroup;
	searchInput: string;
	data$: Observable<any[]>;

	statusData: any[] = [];
	statusOptions: any = {};
	statusOptionsConfig: any = {};
	minDate: Date;
	maxDate: Date;
	constructor(private formBuilder: FormBuilder, private sharedDataService: SharedDataService, private _snackBar: MatSnackBar, private datePipe: DatePipe, private spinner: NgxSpinnerService, private _router: Router, private dialog: MatDialog, public snackBar: MatSnackBar, private layoutUtilsService: LayoutUtilsService, private ref: ChangeDetectorRef, private coffeeService: CoffeeService) {
		this.dispatchplanList$ = this.dispatchplanListSubject.asObservable();

		const currentYear = new Date().getFullYear();
		this.minDate = new Date(currentYear - 20, 0, 1);
		this.maxDate = new Date(currentYear + 1, 11, 31);
	}

	onSubmit() {
		if (this.reportForm.invalid) {
			Object.values(this.reportForm.controls).forEach((control) => control.markAsTouched());
			return;
		}
		const owner_company_id = this.reportForm.get("owner_company_id").value;
		const statusid = this.reportForm.get("statusid").value;
		const orderno = this.reportForm.get("orderno").value;
		const fromDateValue = this.datePipe.transform(this.reportForm.get("fromdate").value, "MM-dd-yyyy");
		const toDateValue = this.datePipe.transform(this.reportForm.get("todate").value, "MM-dd-yyyy");
		this.DispatchPlanGET(fromDateValue, toDateValue, owner_company_id, statusid, orderno);
	}
	resetform() {
		this.reportForm.reset();
		this.currentPage = 1;
		this.DispatchPlanGET(null, null, null, null, null);
	}
	InActiveDispatchPlan(DispatchPlanId) {}
	ngOnInit() {
		this.reportForm = this.formBuilder.group({
			orderno: [""],
			owner_company_id: [""],
			statusid: [""],
			fromdate: ["", Validators.compose([Validators.required])],
			todate: ["", Validators.compose([Validators.required])],
		});

		var userdata = JSON.parse(localStorage.getItem("user"));
		this.statusOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 15,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Status",
			minimumInputLength: 0,
		};

		this.DispatchPlanGET(null, null, null, null, null);
		this.setupSearch();
	}

	editDispatchPlan(dispatchdata: any) {
		this.sharedDataService.setDispatchPlanData(JSON.stringify(dispatchdata));
		this._router.navigate(["/coffee/dispatch-plan"]);
	}

	openModal(DispatchPlanId: number) {
		const dialogRef = this.dialog.open(ViewDispatchComponent, {
			data: { DispatchPlanId: DispatchPlanId },
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	DispatchPlanGET(fromDate: string, toDate: string, owner_company_id: string, statusid: string, orderno: string) {
		this.loadingSubmit = true;
		const startIndex = this.pageIndex; // Set your desired values for the parameters
		const pageSize = this.pageSize;
		const searchBy = "";
		const searchCriteria = "";
		this.spinner.show();
		this.coffeeService.DispatchPlanGET(startIndex, pageSize, searchBy, searchCriteria, fromDate, toDate, owner_company_id, statusid, orderno).subscribe((response: any[]) => {
			this.dispatchplanListSubject.next(response);
			this.loadingSubmit = false;
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.spinner.hide();
		});
	}
	onPageChanged(e) {
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		this.DispatchPlanGET(null, null, null, null, null);
	}

	getSelectOptionsConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 12,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	setupSearch() {
		//Owner
		this.data$ = new Observable<string>((observer) => {
			observer.next(this.searchInput);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeService.dropdownAll(query, "1", "12", "0");
			})
		);
	}
	onPageSizeChange(page) {
		this.currentPage = page;
		this.DispatchPlanGET(null, null, null, null, null);
	}
	onPageChange(page) {
		this.currentPage = page;
		this.DispatchPlanGET(null, null, null, null, null);
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1;
		this.DispatchPlanGET(null, null, null, null, null);
	}

	editUserData(userInfo: string) {
		localStorage.setItem("usersStorage", JSON.stringify(userInfo));
		this._router.navigate(["/coffee/usermaster"]);
	}
}
