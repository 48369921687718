import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoffeeService } from "../../coffee.service";
import { MatSnackBar } from "@angular/material";

@Component({
	selector: "kt-category-edit",
	templateUrl: "./category-edit.component.html",
	styleUrls: ["./category-edit.component.scss"],
})
export class CategoryEditComponent implements OnInit {
	categoryFromGroup: FormGroup;
	loading = false;

	constructor(private formBuilder: FormBuilder, private coffeeservice: CoffeeService, private _snackBar: MatSnackBar) {}

	ngOnInit() {
		this.categoryFromGroup = this.formBuilder.group({
			categoryId: ["0", Validators.required],
			categoryname: ["", Validators.required],
			categorydescriptions: ["", Validators.required],
			UserId: ["0"],
		});
	}

	onSubmit() {
		this.loading = true;
		if (this.categoryFromGroup.invalid) {
			Object.values(this.categoryFromGroup.controls).forEach((control) => control.markAsTouched());
			this.loading = false;
			return;
		}

		const formData = { ...this.categoryFromGroup.value };

		this.coffeeservice.CategorySave(formData).subscribe((res: any) => {
			if (res.error == false) {
				this._snackBar.open("Saved Successfully", "Undo", {
					duration: 3000,
				});
			} else {
				this._snackBar.open("Saved Successfully", "Undo", {
					duration: 3000,
				});
			}
			// this.ownerCompanyForm.reset();
			//  this.router.navigate(['/coffee/sitesdata']);
		});
	}
}
