import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormArray, Validators, FormGroup, AbstractControl, ValidatorFn } from "@angular/forms";
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router } from "@angular/router";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CoffeeService } from "../../coffee.service";

const DEMO_PARAMS = {
	EMAIL: "",
	PASSWORD: "",
};

@Component({
	selector: "kt-usermaster",
	templateUrl: "./usermaster.component.html",
	styleUrls: ["./usermaster.component.scss"],
})
export class UsermasterComponent implements OnInit {
	loading = false;

	userForm: FormGroup;

	stateList$: Observable<any>;
	public CurrentRoleId: string = "0";

	dropdownOptions: any[] = [
		{ id: 1, text: "Option 1" },
		{ id: 2, text: "Option 2" },
		{ id: 3, text: "Option 3" },
	];
	selectedOption: any;

	dropdownOptions$: Observable<any[]>;

	stateDropdown$: Observable<any>;
	constructor(private fb: FormBuilder, private _router: Router, private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private cd: ChangeDetectorRef, private coffeeService: CoffeeService) {}

	initLoginForm() {
		// demo message to show

		this.userForm = this.fb.group({
			userId: ["0"],
			roleId: [DEMO_PARAMS.EMAIL, Validators.required],
			userName: [DEMO_PARAMS.PASSWORD, Validators.required],
			firstName: ["", Validators.required],
			middleName: ["", Validators.required],
			lastName: ["", Validators.required],
			emailId: ["", [Validators.required, emailValidator]],
			mobileNo: ["", [Validators.required, phoneValidator]],
			IsEditMode: [false],
			IsActive: [true],
		}); // Add the custom validator to the form group

		this.roleDropdown();
	}

	onSubmit() {
		this.loading = true;
		if (this.userForm.invalid) {
			// Mark all form controls as touched to trigger the display of error messages
			Object.values(this.userForm.controls).forEach((control) => control.markAsTouched());
			this.loading = false;
			return;
		}

		const formData = { ...this.userForm.value };

		this.coffeeService.saveUsersDetails(formData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
					this._router.navigate(["/coffee/userdata"]);
				}
			},
			(error: any) => {
				this._snackBar.open(error.error, "OK", {
					duration: 3000,
				});
				this.loading = false;
				///console.error(error); // Optionally log the error to the console for debugging purposes
			}
		);

		this.loading = false;
	}
	roleDropdown() {
		//
		this.dropdownOptions$ = this.coffeeService.roleDropdown();
		// this.coffeeService.roleDropdown().subscribe(options => {
		//   debugger;
		//   this.dropdownOptions = options;
		// });
	}

	public RoleChanged(value: any): void {
		console.log("Selected value is: ", value);
		this.userForm.patchValue({
			RoleId: value,
		});
		this.CurrentRoleId = value;
	}
	public handleChange(value: any): void {
		console.log("Selected value is: ", value);
	}
	statedropdown() {
		this.stateDropdown$ = this.coffeeService.dropdownAll("", "1", "2", "0");
	}
	ngOnInit() {
		this.initLoginForm();
		this.statedropdown();
	}
}
function passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
	const password = control.get("password");
	const confirmPassword = control.get("confirmPassword");

	if (password.value !== confirmPassword.value) {
		return { passwordMismatch: true };
	}

	return null;
}
function emailValidator(control: AbstractControl): { [key: string]: any } | null {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	const email = control.value;

	if (email && !emailPattern.test(email)) {
		return { invalidEmail: true };
	}

	return null;
}
function phoneValidator(control: AbstractControl): { [key: string]: any } | null {
	// Define a regular expression for a valid mobile phone number
	const phonePattern = /^[0-9]{10}$/; // You can adjust this pattern to match your specific requirements

	const phone = control.value;

	if (phone && !phonePattern.test(phone)) {
		return { invalidPhone: true };
	}

	return null;
}
