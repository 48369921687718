import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormBuilder, FormGroup, Validators, FormArray, FormGroupName } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router } from "@angular/router";
import { BaseDataSource, QueryParamsModel, LayoutUtilsService, MessageType } from "../../../../core/_base/crud";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialogConfig, MatSort, MatSnackBar, MatDialog, MatDialogRef } from "@angular/material";
import { Action, Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
import { MasterModel } from "../../../../views/pages/masters/masters.model";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { CoffeeService } from "../../../coffee.service";
import { ProductsService } from "../../product.service";
import { ViewProductPricingComponent } from "../../../Modals/view-product-pricing/view-product-pricing.component";
import { EditPricingComponent } from "../../../Modals/edit-pricing/edit-pricing.component";
const MASTER_DATA: MasterModel[] = [];

@Component({
	selector: "kt-product-pricing-data",
	templateUrl: "./product-pricing-data.component.html",
	styleUrls: ["./product-pricing-data.component.scss"],
})
export class ProductPricingDataComponent implements OnInit {
	displayedColumns: string[] = ["MasterName", "SubMasterName", "actions"];
	dataSource = new MatTableDataSource<MasterModel>(MASTER_DATA);
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	public master: MasterModel;
	showSpinner = false;
	public hasItems: boolean;
	totalLength: number = 119;
	pageIndex: number = 1;
	pageSize: number = 10;
	MasterId: number = 0;
	public employeeId: string = "0";
	totalLengthEmitter$ = new BehaviorSubject<number>(this.totalLength);

	masters: [];

	observable: Observable<[]>;
	loading2 = false;
	submitted = false;
	applyLeaveId: number = 0;
	masterList$: Observable<any>;
	tempmasterList$: Observable<any>;
	masterDropDown$: Observable<any>;
	empstatusfrm: FormGroup;
	employeeStatusFormShow = false;
	@Output() messageEvent = new EventEmitter<string>();

	usersList$: Observable<any[]>;
	private usersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	//Pagination
	totalPages: number = 50;
	currentPage: number = 1;
	totalRecords: number = 0;
	itemsPerPage = 10;
	totalItems = 0;
	searchQuery: string = "";

	constructor(private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private spinner: NgxSpinnerService, private _router: Router, private dialog: MatDialog, private store: Store<AppState>, public snackBar: MatSnackBar, private layoutUtilsService: LayoutUtilsService, private ref: ChangeDetectorRef, private _service: ProductsService) {
		this.usersList$ = this.usersListSubject.asObservable();
	}

	openModal(ProductsPricingId: number) {
		const dialogRef = this.dialog.open(ViewProductPricingComponent, {
			data: { ProductsPricingId: ProductsPricingId },
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}
	openModalForEdit(ProductsPricingId: number) {
		const dialogRef = this.dialog.open(EditPricingComponent, {
			data: { ProductsPricingId: ProductsPricingId },
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	ngOnInit() {
		this.getUsers();
	}

	getUsers() {
		const startIndex = this.currentPage;
		const pageSize = this.itemsPerPage;
		const searchBy = this.searchQuery ? "1" : "0";
		const searchCriteria = this.searchQuery;
		this.spinner.show();
		this._service.Products_pricing_master_GET(startIndex, pageSize, searchBy, searchCriteria).subscribe((response: any[]) => {
			this.usersListSubject.next(response);
			this.totalPages = response[0].TotalPages;
			this.totalRecords = response[0].TotalRecords;
			this.spinner.hide();
		});
	}
	onPageChanged(e) {
		this.pageIndex = e.pageIndex + 1;
		this.pageSize = e.pageSize;
		this.getUsers();
	}
	onPageSizeChange(newSize: number) {
		this.itemsPerPage = newSize;
		this.getUsers();
		this.ref.detectChanges();
	}
	editUserData(userInfo: string) {
		localStorage.setItem("usersStorage", JSON.stringify(userInfo));
		this._router.navigate(["/coffee/usermaster"]);
	}

	//Pagination

	onPageChange(page) {
		this.currentPage = page;
		this.getUsers();
	}
	onSearch(query: string) {
		this.searchQuery = query;
		this.currentPage = 1;
		this.getUsers();
	}
}
