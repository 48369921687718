import { Component, Inject, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "kt-view-serail-data",
	templateUrl: "./view-serail-data.component.html",
	styleUrls: ["./view-serail-data.component.scss"],
})
export class ViewSerailDataComponent implements OnInit {
	@Output() removeSerial: EventEmitter<string> = new EventEmitter<string>();
	ordersSerailNoList$: Observable<any[]>;
	private ordersSerailListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	productName: String;
	order_date: String;
	order_id: String;
	orderValue: String;
	company_name: String;
	order_status: String;
	viewLoading = false;
	loading = false;
	hasFormErrors = false;
	constructor(public dialogRef: MatDialogRef<ViewSerailDataComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		const dataSerail = this.data.dataSerail;
		this.ordersSerailNoList$ = this.ordersSerailListSubject.asObservable();
		this.productName = dataSerail.ProductName;
		this.ordersSerailListSubject.next(dataSerail.details);
	}

	ngOnInit() {}

	getTitle(): string {
		return "View Serials";
	}

	onRemoveSerial(serialNo: string) {
		this.removeSerial.emit(serialNo);
	}
}
