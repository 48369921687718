import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { MenuModel } from '../../../app/core/_config/menu.model';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

    public isLoading = new BehaviorSubject(false);
    constructor(private http: HttpClient) { }

	getAsideMenu(): Observable<MenuModel[]> {
		return this.http.get<MenuModel[]>(environment.BaseUrl + 'api/LoginApi/DynamicMenuAsideGET?Search=');
		
		
	}
	getMenuAside(RoleId: string) {

		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
		  'Authorization': 'Bearer ' + userdata.token 
		});
	  
		return this.http.get<any[]>(environment.BaseUrl + 'api/Users/dynamicMenuaside', {
		  headers: headers,
		  params: new HttpParams().set('RoleId', RoleId)
		});
	  }
	  
	// getMenuAside(RoleId: string) {	 
	// 	return this.http.get<any[]>(environment.BaseUrl + 'Users/DynamicMenuAside', {
	// 		params: new HttpParams()
	// 			.set('RoleId', RoleId)
	// 	});
	// }     
   
    postSubmaster(formData) {
		return this.http.post<MenuModel[]>(environment.BaseUrl + '/Sub_MasterInsertUpdate', formData);

    }

	getSubmasterList(): Observable<MenuModel[]> {
        this.isLoading.next(true);
		return this.http.get<MenuModel[]>(environment.BaseUrl + '/Sub_MasterGET?MasterId=0&Search=');
    }
	 
	deleteMaster(SubMasterId): Observable<MenuModel[]> {
		return this.http.get<MenuModel[]>(environment.BaseUrl + '/Sub_MasterTableInActive?SubMasterId=' + SubMasterId);
    }
    
}
