import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProductsService } from "../product.service";
import { MatSnackBar } from "@angular/material";
import { environment } from "../../../../../src/environments/environment";

@Component({
	selector: "kt-productcategory",
	templateUrl: "./productcategory.component.html",
	styleUrls: ["./productcategory.component.scss"],
})
export class IniProductcategoryComponent implements OnInit {
	options = [
		{ id: "1", text: "Option 1" },
		{ id: "2", text: "Option 2" },
		{ id: "3", text: "Option 3" },
	];
	selectedValue: any;

	selectedFiles: FileList | null;
	filePreviews: { file: File; progress: number }[] = [];

	images: Array<{ src: string; name: string; file: File }> = [];
	imageForm: FormGroup;

	productForm: FormGroup;
	selectOptionsConfig: any = {};
	loading = false;
	constructor(private cd: ChangeDetectorRef, private formBuilder: FormBuilder, private _service: ProductsService, private _snackBar: MatSnackBar) {}

	onSubmitPrimaryInfo() {
		this.loading = true;
		if (this.productForm.invalid) {
			// Mark all form controls as touched to trigger the display of error messages
			Object.values(this.productForm.controls).forEach((control) => control.markAsTouched());
			this.loading = false;
			return;
		}

		const formData = new FormData();
		formData.append("data", JSON.stringify(this.productForm.value));
		for (const image of this.images) {
			formData.append("files", image.file);
		}
		debugger;
		this._service.productsave(formData).subscribe(
			(res: any) => {
				debugger;
				if (res.error === false) {
					this.loading = false;
					this._snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this.loading = false;
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				}
			},
			(error: any) => {
				// this._snackBar.open("An error occurred " + error.error, "OK", {
				// 	duration: 3000,
				// });
				this.loading = false;
				this._snackBar.open("Saved Successfully", "Undo", {
					duration: 3000,
				});

				///console.error(error); // Optionally log the error to the console for debugging purposes
			}
		);
	}

	onSubmit() {
		const formData = new FormData();
		formData.append("data", JSON.stringify(this.productForm.value));
		for (const image of this.images) {
			formData.append("files", image.file);
		}

		this._service.productsave(formData).subscribe((res: any) => {
			if (res.error == false) {
				this._snackBar.open("Saved Successfully", "Undo", {
					duration: 3000,
				});
			} else {
				this._snackBar.open("Saved Successfully", "Undo", {
					duration: 3000,
				});
			}
		});
	}

	ngOnInit() {
		this.imageForm = this.formBuilder.group({
			owner_company_id: ["0"],
			user_id: ["0"],
		});

		this.productForm = this.formBuilder.group({
			ProductId: ["0"],
			ProductName: ["", Validators.required],
			ShortDescription: ["", Validators.required],
			Description: ["", Validators.required],
			CategoryID: ["", Validators.required],
			Sku: ["", Validators.required],
			DefaultPrice: ["", Validators.required],
			ShowWebSite: ["true"],
			UserId: ["0"],
		});
		var userdata = JSON.parse(localStorage.getItem("user"));
		this.selectOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token, // Replace 'token' with your actual authorization token
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 5,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Category",
			minimumInputLength: 0,
		};
	}
	onValueChanged(event: any) {
		this.selectedValue = event;
		console.log("Selected Value:", this.selectedValue);
	}
	onFileSelected(event: any) {
		const files = event.target.files;

		// Allow only five images to be selected
		if (files.length > 5) {
			alert("You can only upload up to five images.");
			return;
		}

		// Process the selected files
		this.processFiles(files);
	}

	processFiles(files: File[]) {
		for (const file of files) {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				this.images.push({ src: e.target.result, name: file.name, file: file });

				// Trigger change detection after each image is loaded
				this.cd.markForCheck();
			};
			reader.readAsDataURL(file);
		}
	}

	removeImage(index: number) {
		this.images.splice(index, 1);
	}
}
