import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "kt-view-order",
	templateUrl: "./view-order.component.html",
	styleUrls: ["./view-order.component.scss"],
})
export class ViewOrderComponent implements OnInit {
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	loading = false;
	ordersList$: Observable<any[]>;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	order_no: String;
	order_date: String;
	order_id: String;
	orderValue: String;
	company_name: String;
	order_status: String;

	constructor(public dialogRef: MatDialogRef<ViewOrderComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		const orderId = this.data.orderId;
		this.ordersList$ = this.ordersListSubject.asObservable();

		this.OrdersDetailsGET(orderId);
		console.log("Received Order ID:", orderId);
	}

	OrdersDetailsGET(order_id) {
		this.spinner.show();
		this.coffeeService.OrdersDetailsGET(order_id).subscribe((response: any[]) => {
			this.ordersListSubject.next(response);
			this.spinner.hide();
			this.orderValue = response[0].order_value;
			this.order_no = response[0].order_no;
			this.order_date = response[0].order_date;
			this.company_name = response[0].company_name;
			this.order_status = response[0].order_status;
		});
	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.customerForm = this.fb.group({
			firstName: [" ", Validators.required],
			lastName: ["", Validators.required],
			email: ["", Validators.compose([Validators.required, Validators.email])],
			dob: ["", Validators.compose([Validators.nullValidator])],
			userName: ["", Validators.compose([Validators.required])],
			gender: ["", Validators.compose([Validators.required])],
			ipAddress: ["", Validators.compose([Validators.required])],
			type: ["", Validators.compose([Validators.required])],
		});
	}
	getTitle(): string {
		return "View Order";
	}
}
