import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { MasterModel } from '../../pages/masters/masters.model';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MastersService {

    public isLoading = new BehaviorSubject(false);
    constructor(private http: HttpClient) { }

    getMasterList() {
        return this.http.get<MasterModel[]>(environment.BaseUrl + '/MainMasterGET?Search=');

	}

	MasterListDropDown() {
		return this.http.get<any[]>(environment.BaseUrl + '/MainMasterGET?Search=');

	}
   
    postSubmaster(formData) {
        return this.http.post<MasterModel[]>(environment.BaseUrl + '/Sub_MasterInsertUpdate', formData);

    }

	getSubmasterList(MasterId:number=0,pageIndex: number = 1, pageSize: number): Observable<MasterModel[]> {
        this.isLoading.next(true);
		return this.http.get<MasterModel[]>(environment.BaseUrl + '/Sub_MasterGET',{
			params: new HttpParams()
				.set('MasterId', MasterId.toString())
				.set('Search', "")
				.set('pageIndex', pageIndex.toString())
				.set('pageSize', pageSize.toString())
		});
    }
	SubmasterList(pageIndex: number = 1, pageSize: number) {
		this.isLoading.next(true);
		return this.http.get<any[]>(environment.BaseUrl + '/Sub_MasterGET', {
			params: new HttpParams()
				.set('MasterId', "0")
				.set('Search', "")
				.set('pageIndex', pageIndex.toString())
				.set('pageSize', pageSize.toString())
		});
	}

    deleteMaster(SubMasterId): Observable<MasterModel[]> {
        return this.http.get<MasterModel[]>(environment.BaseUrl + '/Sub_MasterTableInActive?SubMasterId=' + SubMasterId);
    }
    
}
