import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class ProductsService {
	public isLoading = new BehaviorSubject(false);
	constructor(private http: HttpClient) {}

	ProductsPriceSave(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Products/productsPriceSave", formData, { headers });
	}

	productsGET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string, categroyid: any[]): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria).set("tableDataJsons", JSON.stringify(categroyid));
		return this.http.get<any[]>(environment.BaseUrl + "api/Products/productsGET", {
			headers: headers,
			params: params,
		});
	}

	ProductsStockGet(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria);
		return this.http.get<any[]>(environment.BaseUrl + "api/Products/ProductsStockGet", {
			headers: headers,
			params: params,
		});
	}

	Products_pricing_master_GET(startIndex: number, pageSize: number, searchBy: string, searchCriteria: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("StartIndex", startIndex.toString()).set("PageSize", pageSize.toString()).set("SearchBy", searchBy).set("SearchCriteria", searchCriteria);
		return this.http.get<any[]>(environment.BaseUrl + "api/Products/Products_pricing_master_GET", {
			headers: headers,
			params: params,
		});
	}

	Products_pricing_detailsGET(ProductsPricingId: number): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("ProductsPricingId", ProductsPricingId.toString());
		return this.http.get<any[]>(environment.BaseUrl + "api/Products/Products_pricing_detailsGET", {
			headers: headers,
			params: params,
		});
	}

	ProductsImagesDelete(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Products/ProductsImagesDelete", formData, { headers });
	}

	ProductsImagesGetById(ProductId: number): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("ProductId", ProductId.toString());
		return this.http.get<any[]>(environment.BaseUrl + "api/Products/ProductsImagesGetById", {
			headers: headers,
			params: params,
		});
	}

	roleDropdown(): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		return this.http.get<any[]>(environment.BaseUrl + "api/Users/roleMasterGet", {
			headers: headers,
			params: new HttpParams().set("RoleId", "0"),
		});
	}
	productsave(formData) {
		const headers = new HttpHeaders();
		headers.append("Content-Type", "multipart/form-data");

		return this.http.post<any[]>(environment.BaseUrl + "api/Products/productsave", formData, { headers: headers });
	}

	saveUsersDetails(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Users/masterUserInsertUpdate", formData, { headers });
	}
	OwnercompanyInsertUpdate(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/OwnserCompany/ownercompanyInsertUpdate", formData, { headers });
	}

	dropdownAll(searchTerm: string, page: string, type: string, parentId: string): Observable<any[]> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		console.log(userdata.token);

		const headers = new HttpHeaders({
			Authorization: "Bearer " + userdata.token,
		});

		const params = new HttpParams().set("searchTerm", searchTerm.toString()).set("page", page.toString()).set("type", type.toString()).set("parentId", parentId.toString());

		return this.http.get<any[]>(environment.BaseUrl + "api/Owner/allDropdown", {
			headers: headers,
			params: params,
		});
	}

	deleteFromTable(formData) {
		var userdata = JSON.parse(localStorage.getItem("user"));
		const token = userdata.token;
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		});

		return this.http.post<any[]>(environment.BaseUrl + "api/Owner/deleteFromTable", formData, { headers });
	}
}
