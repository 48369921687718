import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MenuModel } from '../../core/_config/menu.model';
import { MenuService } from '../../core/_config/menu.service';

export class MenuConfig {

	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Dashboards',
					root: true,
					alignment: 'left',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
				},
				{
					title: 'Components',
					root: true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Google Material',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
							submenu: [
								{
									title: 'Form Controls',
									bullet: 'dot',
									submenu: [
										{
											title: 'Auto Complete',
											page: '/material/form-controls/autocomplete',
											permission: 'accessToECommerceModule'
										},
										{
											title: 'Checkbox',
											page: '/material/form-controls/checkbox'
										},
										{
											title: 'Radio Button',
											page: '/material/form-controls/radiobutton'
										},
										{
											title: 'Datepicker',
											page: '/material/form-controls/datepicker'
										},
										{
											title: 'Form Field',
											page: '/material/form-controls/formfield'
										},
										{
											title: 'Input',
											page: '/material/form-controls/input'
										},
										{
											title: 'Select',
											page: '/material/form-controls/select'
										},
										{
											title: 'Slider',
											page: '/material/form-controls/slider'
										},
										{
											title: 'Slider Toggle',
											page: '/material/form-controls/slidertoggle'
										}
									]
								},
								{
									title: 'Navigation',
									bullet: 'dot',
									submenu: [
										{
											title: 'Menu',
											page: '/material/navigation/menu'
										},
										{
											title: 'Sidenav',
											page: '/material/navigation/sidenav'
										},
										{
											title: 'Toolbar',
											page: '/material/navigation/toolbar'
										}
									]
								},
								{
									title: 'Layout',
									bullet: 'dot',
									submenu: [
										{
											title: 'Card',
											page: '/material/layout/card'
										},
										{
											title: 'Divider',
											page: '/material/layout/divider'
										},
										{
											title: 'Expansion panel',
											page: '/material/layout/expansion-panel'
										},
										{
											title: 'Grid list',
											page: '/material/layout/grid-list'
										},
										{
											title: 'List',
											page: '/material/layout/list'
										},
										{
											title: 'Tabs',
											page: '/material/layout/tabs'
										},
										{
											title: 'Stepper',
											page: '/material/layout/stepper'
										},
										{
											title: 'Default Forms',
											page: '/material/layout/default-forms'
										},
										{
											title: 'Tree',
											page: '/material/layout/tree'
										}
									]
								},
								{
									title: 'Buttons & Indicators',
									bullet: 'dot',
									submenu: [
										{
											title: 'Button',
											page: '/material/buttons-and-indicators/button'
										},
										{
											title: 'Button toggle',
											page: '/material/buttons-and-indicators/button-toggle'
										},
										{
											title: 'Chips',
											page: '/material/buttons-and-indicators/chips'
										},
										{
											title: 'Icon',
											page: '/material/buttons-and-indicators/icon'
										},
										{
											title: 'Progress bar',
											page: '/material/buttons-and-indicators/progress-bar'
										},
										{
											title: 'Progress spinner',
											page: '/material/buttons-and-indicators/progress-spinner'
										},
										{
											title: 'Ripples',
											page: '/material/buttons-and-indicators/ripples'
										}
									]
								},
								{
									title: 'Popups & Modals',
									bullet: 'dot',
									submenu: [
										{
											title: 'Bottom sheet',
											page: '/material/popups-and-modals/bottom-sheet'
										},
										{
											title: 'Dialog',
											page: '/material/popups-and-modals/dialog'
										},
										{
											title: 'Snackbar',
											page: '/material/popups-and-modals/snackbar'
										},
										{
											title: 'Tooltip',
											page: '/material/popups-and-modals/tooltip'
										}
									]
								},
								{
									title: 'Data table',
									bullet: 'dot',
									submenu: [
										{
											title: 'Paginator',
											page: '/material/data-table/paginator'
										},
										{
											title: 'Sort header',
											page: '/material/data-table/sort-header'
										},
										{
											title: 'Table',
											page: '/material/data-table/table'
										}
									]
								}
							]
						},
						{
							title: 'Ng-Bootstrap',
							bullet: 'dot',
							icon: 'flaticon-web',
							submenu: [
								{
									title: 'Accordion',
									page: '/ngbootstrap/accordion'
								},
								{
									title: 'Alert',
									page: '/ngbootstrap/alert'
								},
								{
									title: 'Buttons',
									page: '/ngbootstrap/buttons'
								},
								{
									title: 'Carousel',
									page: '/ngbootstrap/carousel'
								},
								{
									title: 'Collapse',
									page: '/ngbootstrap/collapse'
								},
								{
									title: 'Datepicker',
									page: '/ngbootstrap/datepicker'
								},
								{
									title: 'Dropdown',
									page: '/ngbootstrap/dropdown'
								},
								{
									title: 'Modal',
									page: '/ngbootstrap/modal'
								},
								{
									title: 'Pagination',
									page: '/ngbootstrap/pagination'
								},
								{
									title: 'Popover',
									page: '/ngbootstrap/popover'
								},
								{
									title: 'Progressbar',
									page: '/ngbootstrap/progressbar'
								},
								{
									title: 'Rating',
									page: '/ngbootstrap/rating'
								},
								{
									title: 'Tabs',
									page: '/ngbootstrap/tabs'
								},
								{
									title: 'Timepicker',
									page: '/ngbootstrap/timepicker'
								},
								{
									title: 'Tooltips',
									page: '/ngbootstrap/tooltip'
								},
								{
									title: 'Typehead',
									page: '/ngbootstrap/typehead'
								}
							]
						},
					]
				},
				{
					title: 'Applications',
					root: true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'eCommerce',
							bullet: 'dot',
							icon: 'flaticon-business',
							permission: 'accessToECommerceModule',
							submenu: [
								{
									title: 'Customers',
									page: '/ecommerce/customers'
								},
								{
									title: 'Products',
									page: '/ecommerce/products'
								},
							]
						},
						{
							title: 'User Management',
							bullet: 'dot',
							icon: 'flaticon-user',
							submenu: [
								{
									title: 'Users',
									page: '/user-management/users'
								},
								{
									title: 'Roles',
									page: '/user-management/roles'
								}
							]
						},
					]
				},
				{
					title: 'Custom',
					root: true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Error Pages',
							bullet: 'dot',
							icon: 'flaticon2-list-2',
							submenu: [
								{
									title: 'Error 1',
									page: '/error/error-v1'
								},
								{
									title: 'Error 2',
									page: '/error/error-v2'
								},
								{
									title: 'Error 3',
									page: '/error/error-v3'
								},
								{
									title: 'Error 4',
									page: '/error/error-v4'
								},
								{
									title: 'Error 5',
									page: '/error/error-v5'
								},
								{
									title: 'Error 6',
									page: '/error/error-v6'
								},
							]
						},
						{
							title: 'Wizard',
							bullet: 'dot',
							icon: 'flaticon2-mail-1',
							submenu: [
								{
									title: 'Wizard 1',
									page: '/wizard/wizard-1'
								},
								{
									title: 'Wizard 2',
									page: '/wizard/wizard-2'
								},
								{
									title: 'Wizard 3',
									page: '/wizard/wizard-3'
								},
								{
									title: 'Wizard 4',
									page: '/wizard/wizard-4'
								},
							]
						},
					]
				},
			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				{section: 'Payroll Settings'},
				{
					title: 'Company Settings',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-browser-2',
					submenu: [
						{
                            title: 'Company Configuration',
                            root: true,
                            page: '/companyconfig/company-config-edit'
                        },
                        {
                            title: 'Company Details',
                            root: true,
                            page: '/companyconfig/company-config-list'
                        },
                        {
                            title: 'Bank Edit',
                            root: true,
                            page: '/companyconfig/bank-edit'
                        },
                        {
                            title: 'Bank List',
                            root: true,
                            page: '/companyconfig/bank-list'
                        },
                        {
                            title: 'Tax Edit',
                            root: true,
                            page: '/companyconfig/tax-edit'
                        },
                        {
                            title: 'Tax List',
                            root: true,
                            page: '/companyconfig/tax-list'
                        },
                        {
                            title: 'EPF Edit',
                            root: true,
                            page: '/companyconfig/epf-edit'
                        },
                        
                        {
                            title: 'SalaryComponent Edit',
                            root: true,
                            page: '/companyconfig/salary-component-edit'
                        },
                        {
                            title: 'SalaryComponent List',
                            root: true,
                            page: '/companyconfig/salary-component-list'
                        },
                        {
                            title: 'Pay Schedule',
                            root: true,
                            page: '/companyconfig/payschedule-edit'
                        },
                        {
                            title: 'ESI Edit',
                            root: true,
                            page: '/companyconfig/esi-edit'
                        },
                        {
                            title: 'Statutory Components',
                            root: true,
                            page: '/companyconfig/statutory-components-list'
                        },
                        {
                            title: 'Professional Tax',
                            root: true,
                            page: '/companyconfig/professional-tax-edit'
						},
						{
							title: 'Financial Year ',
							root: true,
							page: '/companyconfig/financial-year-list'
						},
					]
				},
				{
					title: 'Masters',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-digital-marketing',
					submenu: [
                        {
                            title: 'Masters',
                            root: true,
                            page: '/masters/master-list'
                        },
                        {
                            title: 'Image Upload',
                            root: true,
                            page: '/masters/imageUpload'
                        },
					]
                },
                {
                    title: 'Schedules',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-digital-marketing',
                    submenu: [
                        {
                            title: 'Schedule',
                            page: '/schedule/schedule-list'
                        },
                    ]
                },
                {
                    title: 'Demo',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-digital-marketing',
                    submenu: [
                        {
                            title: 'Demo-Edit',
                            page: '/demo/demo-edit'
                        },
                    ]
                },
                { section: 'Applications' },
                {
                    title: 'Attendance',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-list-2',
                    submenu: [
                        {
                            title: 'Attendance Edit',
                            page: '/attendance/attendance- edit'
                        },
                        {
                            title: 'Attendance List',
                            page: '/attendance/attendance-list'
						},
						{
							title: 'Timeout-Edit',
							page: '/attendance/timeout-edit'
						}
                    ]
                },
                {
                    title: 'Employee',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-user-outline-symbol',
                    submenu: [
                        {
                            title: 'Employee Edit',
                            page: '/employee/employee-edit'
                        },
                        {
                            title: 'Employee List',
							page: '/employee/list'
                        },
                        {
                            title: 'Overtime',
                            page: '/employee/employee-edit'
                        },
                        {
                            title: 'Cash Advance',
                            page: '/user-management/roles'
                        },
                        {
                            title: 'Schedules',
                            page: '/schedule/emp-schedule-list'
                        }
                    ]
                },
				{
					title: 'eCommerce',
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					root: true,
					permission: 'accessToECommerceModule',
					submenu: [
						{
							title: 'Customers',
							page: '/ecommerce/customers'
						},
						{
							title: 'Products',
							page: '/ecommerce/products'
						},
					]
				},
				{
					title: 'User Management',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					submenu: [
						{
							title: 'Users',
							page: '/user-management/users'
						},
						{
							title: 'Roles',
							page: '/user-management/roles'
						}
					]
				},
                { section: 'Leave System'},
				{
                    title: 'Leave Management System',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-list-2',
					submenu: [
                        {
                            title: 'HoliDay',
                            page: '/lms/holiday-list'
                        },
                        {
                            title: 'Leave Type',
                            page: '/lms/leave-type-list'
                        },
						{
							title: 'Leave Description',
							page: '/lms/leave-Des-list'
						},
						{
                            title: 'Leave Edit',
                            page: '/lms/leave-edit'
                        },
                        {
                            title: 'Leave List',
                            page: '/lms/leave-list'
                        },
					]
				},
				{
					title: 'Wizard',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-mail-1',
					submenu: [
						{
							title: 'Wizard 1',
							page: '/wizard/wizard-1'
						},
						{
							title: 'Wizard 2',
							page: '/wizard/wizard-2'
						},
						{
							title: 'Wizard 3',
							page: '/wizard/wizard-3'
						},
						{
							title: 'Wizard 4',
							page: '/wizard/wizard-4'
						},
					]
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
