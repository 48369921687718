// Angular
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// NGRX
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
// Translate
import { TranslateModule } from "@ngx-translate/core";
import { PartialsModule } from "../views/partials/partials.module";
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService } from "../core/_base/crud";
// Shared
import { ActionNotificationComponent } from "../views/partials/content/crud";
// Components
import { UserManagementComponent } from "../views/pages/user-management/user-management.component";
import { UsersListComponent } from "../views/pages/user-management/users/users-list/users-list.component";
import { UserEditComponent } from "../views/pages/user-management/users/user-edit/user-edit.component";
import { RolesListComponent } from "../views/pages/user-management/roles/roles-list/roles-list.component";
import { RoleEditDialogComponent } from "../views/pages/user-management/roles/role-edit/role-edit.dialog.component";
import { UserRolesListComponent } from "../views/pages/user-management/users/_subs/user-roles/user-roles-list.component";
import { ChangePasswordComponent } from "../views/pages/user-management/users/_subs/change-password/change-password.component";
import { AddressComponent } from "../views/pages/user-management/users/_subs/address/address.component";
import { SocialNetworksComponent } from "../views/pages/user-management/users/_subs/social-networks/social-networks.component";
import { ForbiddenComponent } from "../auth/forbidden/forbidden.component";
// Material
import { MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatSelectModule, MatMenuModule, MatProgressBarModule, MatButtonModule, MatCheckboxModule, MatDialogModule, MatTabsModule, MatNativeDateModule, MatCardModule, MatRadioModule, MatIconModule, MatDatepickerModule, MatExpansionModule, MatAutocompleteModule, MAT_DIALOG_DEFAULT_OPTIONS, MatSnackBarModule, MatTooltipModule } from "@angular/material";
import { usersReducer, UserEffects } from "../core/auth";
import { SitemasterComponent } from "../coffee/site/sitemaster/sitemaster.component";
import { UsermasterComponent } from "../coffee/users/usermaster/usermaster.component";

import { NgSelect2Module } from "ng-select2";

import { CoffeeComponent } from "../coffee/coffee.component";
import { UsersdataComponent } from "./usersdata/usersdata.component";
import { SitesDataComponent } from "./sites-data/sites-data.component";
import { OwnersComponent } from "./owners/owners.component";
import { OwnersDataComponent } from "./owners-data/owners-data.component";
import { IniProductcategoryComponent } from "./products/productcategory/productcategory.component";
import { IniProductComponent } from "./products/product/product.component";
import { ProductPricingComponent } from "./products/product-pricing/product-pricing.component";

import { CustomerEditComponent } from "./customers/customer-edit/customer-edit.component";
import { CustomerDataComponent } from "./customers/customer-data/customer-data.component";
import { ProductsDataPageComponent } from "./products/products-data-page/products-data-page.component";
import { OrdersEditComponent } from "./Orders/orders-edit/orders-edit.component";
import { EmailConfigComponent } from "./Settings/email-config/email-config.component";
import { CategoryEditComponent } from "./Category/category-edit/category-edit.component";
import { CategoryDataComponent } from "./Category/category-data/category-data.component";
import { ViewOrderComponent } from "./Modals/view-order/view-order.component";
import { DispatchPlanComponent } from "./Dispatch/dispatch-plan/dispatch-plan.component";
import { ActualDispatchComponent } from "./Dispatch/actual-dispatch/actual-dispatch.component";
import { DispatchPlanDataComponent } from "./Dispatch/dispatch-plan/dispatch-plan-data/dispatch-plan-data.component";
import { ViewDispatchComponent } from "./Modals/view-dispatch/view-dispatch.component";
import { ConfirmationModalComponent } from "./Modals/confirmation-modal/confirmation-modal.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { RptOrderDispatchComponent } from "./Reports/rpt-order-dispatch/rpt-order-dispatch.component";
import { ProductPricingDataComponent } from "../coffee/products/product-pricing/product-pricing-data/product-pricing-data.component";
import { ViewProductPricingComponent } from "./Modals/view-product-pricing/view-product-pricing.component";
import { ViewUserComponent } from "./Modals/view-user/view-user.component";
import { EditUserComponent } from "./users/edit-user/edit-user.component";
import { EditPricingComponent } from "./Modals/edit-pricing/edit-pricing.component";
import { ProductStockComponent } from "./products/product-stock/product-stock.component";
import { AdjustStockComponent } from "./Modals/adjust-stock/adjust-stock.component";
import { ActualDisptchDataComponent } from "./Dispatch/actual-disptch-data/actual-disptch-data.component";
import { ActualViewDisptchComponent } from "./Modals/actual-view-disptch/actual-view-disptch.component";
import { ViewSerailDataComponent } from "./Modals/view-serail-data/view-serail-data.component";
const routes: Routes = [
	{
		path: "",
		component: CoffeeComponent,
		children: [
			{
				path: "",
				redirectTo: "coffee",
				pathMatch: "full",
			},
			{
				path: "edit-user",
				component: EditUserComponent,
				data: { user: null },
			},
			{
				path: "product-stock",
				component: ProductStockComponent,
				data: { user: null },
			},
			{
				path: "product-pricing-data",
				component: ProductPricingDataComponent,
			},
			{
				path: "report-order-dispatch",
				component: RptOrderDispatchComponent,
			},
			{
				path: "dispatch-plan-data",
				component: DispatchPlanDataComponent,
			},
			{
				path: "dispatch-plan",
				component: DispatchPlanComponent,
			},
			{
				path: "actual-dispatch",
				component: ActualDispatchComponent,
			},
			{
				path: "orders",
				component: OrdersEditComponent,
			},
			{
				path: "products-data-page",
				component: ProductsDataPageComponent,
			},
			{
				path: "products-pricing",
				component: ProductPricingComponent,
			},
			{
				path: "products",
				component: IniProductComponent,
			},
			{
				path: "product-category",
				component: IniProductcategoryComponent,
			},
			{
				path: "ownsers-data",
				component: OwnersDataComponent,
			},
			{
				path: "ownsers",
				component: OwnersComponent,
			},
			{
				path: "sitesdata",
				component: SitesDataComponent,
			},
			{
				path: "userdata",
				component: UsersdataComponent,
			},
			{
				path: "sitemaster",
				component: SitemasterComponent,
			},
			{
				path: "usermaster",
				component: UsermasterComponent,
			},
			{
				path: "coffee",
				component: CoffeeComponent,
			},
			{
				path: "email",
				component: EmailConfigComponent,
			},
			{
				path: "categoryentry",
				component: CategoryEditComponent,
			},
			{
				path: "categorydata",
				component: CategoryDataComponent,
			},
			{
				path: "actual-dispatch-data-page",
				component: ActualDisptchDataComponent,
			},
		],
	},
];

@NgModule({
	imports: [CommonModule, HttpClientModule, NgSelect2Module, PartialsModule, RouterModule.forChild(routes), StoreModule.forFeature("users", usersReducer), EffectsModule.forFeature([UserEffects]), FormsModule, ReactiveFormsModule, TranslateModule.forChild(), MatButtonModule, MatMenuModule, MatSelectModule, MatInputModule, MatTableModule, MatAutocompleteModule, MatRadioModule, MatIconModule, MatNativeDateModule, MatProgressBarModule, MatDatepickerModule, MatCardModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatProgressSpinnerModule, MatSnackBarModule, MatExpansionModule, MatTabsModule, MatTooltipModule, MatDialogModule],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true,
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: "kt-mat-dialog-container__wrapper",
				height: "auto",
				width: "900px",
			},
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		DatePipe,
	],
	entryComponents: [SitemasterComponent, ViewOrderComponent, ViewDispatchComponent, ConfirmationModalComponent, ViewProductPricingComponent, EditPricingComponent, AdjustStockComponent, ActualViewDisptchComponent, ViewSerailDataComponent],
	declarations: [CoffeeComponent, ActualViewDisptchComponent, ActualDisptchDataComponent, SitemasterComponent, UsermasterComponent, UsersdataComponent, SitesDataComponent, ForbiddenComponent, OwnersComponent, OwnersDataComponent, IniProductcategoryComponent, IniProductComponent, ProductPricingComponent, CustomerEditComponent, CustomerDataComponent, ProductsDataPageComponent, OrdersEditComponent, EmailConfigComponent, CategoryEditComponent, CategoryDataComponent, ViewOrderComponent, DispatchPlanComponent, ActualDispatchComponent, DispatchPlanDataComponent, ViewDispatchComponent, ConfirmationModalComponent, PaginationComponent, RptOrderDispatchComponent, ProductPricingDataComponent, ViewProductPricingComponent, ViewUserComponent, EditUserComponent, EditPricingComponent, ProductStockComponent, AdjustStockComponent, ViewSerailDataComponent],
})
export class CoffeeModule {}
