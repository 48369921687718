import { ChangeDetectorRef, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ViewOrderComponent } from "../../Modals/view-order/view-order.component";
import { environment } from "../../../../../src/environments/environment";
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ViewSerailDataComponent } from "../../Modals/view-serail-data/view-serail-data.component";

@Component({
	selector: "kt-actual-dispatch",
	templateUrl: "./actual-dispatch.component.html",
	styleUrls: ["./actual-dispatch.component.scss"],
})
export class ActualDispatchComponent implements OnInit {
	statusData: any[] = [];
	statusOptions: any = {};
	statusOptionsConfig: any = {};
	public form: FormGroup;
	public companyData: any;
	scannedBarcode: string = "";
	ordersPlanList: any[] = [];
	total: number = 0.0;
	submitted = false;
	loading = false;
	selectedOrderDetails: any[]; // Variable to store selected order details
	@ViewChild("audioElement", { static: true }) audioElement: ElementRef;

	constructor(private fb: FormBuilder, private _router: Router, private dialog: MatDialog, public snackBar: MatSnackBar, private ref: ChangeDetectorRef, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		var userdata = JSON.parse(localStorage.getItem("user"));

		this.statusOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 12,
						parentId: 0,
					};
				},
				processResults: (data: any) => {
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owner",
			minimumInputLength: 0,
		};
	}

	calculateTotal() {
		this.total = this.ordersPlanList.reduce((sum, element) => sum + element.item_price * element.ScanQty, 0.0);
	}

	validateBarcode() {
		const owner_company_id = this.form.value.customerId;
		const barcodeToValidate = this.form.get("scannedBarcode").value;

		const scannedBarcode = this.form.get("scannedBarcode").value;
		const parts = scannedBarcode.split(/[-.]/);

			const partsSerail = scannedBarcode.split(/[-]/);

			console.log(partsSerail);

		if (parts.length >= 3) {
			const extractedValue = parts[0];
			
			//Assuming value 113-22.12.003065  ProductId-Year.month.SerialNo
			this.coffeeService.DispatchPlanDetailsBarcodScanner(extractedValue, owner_company_id, partsSerail[1]).subscribe(
				(res: any) => {
					console.log("Barcode LOG:", res);
					if (res && res[0]) {
						this.form.patchValue({
							customerId: res[0].owner_company_id,
							scannedBarcode: barcodeToValidate,
							ProductName: res[0].ProductName,
							PlanQty: res[0].ScanQty,
							item_price: res[0].item_price,
						});

						const dispatchPlanIdToMatch = res[0].DispatchPlanDetailsId;
						const scanQtyToAdd = res[0].ScanQty;

						const existingItem = this.ordersPlanList.find((item) => item.DispatchPlanDetailsId === dispatchPlanIdToMatch);

						if (existingItem) {
							const newScanQty = existingItem.ScanQty + scanQtyToAdd;

							if (newScanQty <= existingItem.PlanQty) {
								const checkSerailExists = existingItem.details.find((serialno) => serialno.serialNos ===  partsSerail[1]);

								if (checkSerailExists) {
									this.snackBar.open("SerialNo is already added.", "OK", {
										duration: 3000,
									});
									this.playSound("error.mpeg");
								} else {
									existingItem.ScanQty = newScanQty;
									existingItem.details.push({ serialNos:  partsSerail[1], serailDate: parts[1] + "-" + parts[2],BarcodeValue:scannedBarcode });
									this.form.get("scannedBarcode").patchValue("");
									this.playSound("okay-1.wav");
								}
							} else {
								this.snackBar.open("Adding Scan Qty would exceed PlanQty", "OK", {
									duration: 3000,
								});
								this.playSound("error.mpeg");
							}
						} else {
							this.ordersPlanList.push({
								customerId: res[0].owner_company_id,
								order_no: res[0].order_no,
								order_date: res[0].order_date_formatted,
								order_qty: res[0].order_qty,
								scannedBarcode: barcodeToValidate,
								ProductName: res[0].ProductName,
								PlanQty: res[0].PlanQty,
								item_price: res[0].item_price,
								details: [{ serialNos: partsSerail[1], serailDate: parts[1] + "-" + parts[2],BarcodeValue:scannedBarcode}],
								//details: [{ serialNos: parts[3] }, { serailDate: parts[1] + "-" + parts[2] }],
								order_id: res[0].order_id,
								orders_details_id: res[0].Orders_details_id,
								DispatchPlanDetailsId: res[0].DispatchPlanDetailsId,
								ProductId: res[0].product_id,
								ImsMappingId: res[0].ImsMappingId,
								DocektFileUrl: res[0].DocektFileUrl,
								InvoiceFileUrl: res[0].InvoiceFileUrl,
								ScanQty: scanQtyToAdd <= res[0].PlanQty ? scanQtyToAdd : res[0].PlanQty,
							});
							console.log("OrderPlanList:>>",this.ordersPlanList);
							this.form.get("scannedBarcode").patchValue("");
							this.playSound("okay-1.wav");
						}
						this.calculateTotal();
						this.ref.markForCheck();
						this.ref.detectChanges();
					} else {
						this.playSound("error.mpeg");
						this.snackBar.open("Barcode is not associated with Customer or Product not found", "OK", {
							duration: 3000,
						});
					}
				},
				(error: any) => {
					this.snackBar.open(error.error.message, "OK", {
						duration: 3000,
					});
					this.playSound("error.mpeg");
				}
			);
		} else {
			this.snackBar.open("Invalid scanned Barcode format", "OK", {
				duration: 3000,
			});
			this.playSound("error.mpeg");
		}
	}
	removeProduct(productName: string) {
		const index = this.ordersPlanList.findIndex((item) => item.ProductName === productName);
		if (index !== -1) {
			this.ordersPlanList.splice(index, 1);
			this.calculateTotal();
			this.ref.markForCheck();
			this.ref.detectChanges();
		}
	}
	openModal(element: any) {
		this.selectedOrderDetails = element;
		const dialogRef = this.dialog.open(ViewSerailDataComponent, {
			data: { dataSerail: this.selectedOrderDetails },
		});

		dialogRef.componentInstance.removeSerial.subscribe((serialNo: string) => {
			console.log("Serial No to be removed:", serialNo);
			console.log("Current ordersPlanList:", this.ordersPlanList);

			// Iterate through ordersPlanList
			for (let i = 0; i < this.ordersPlanList.length; i++) {
				const order = this.ordersPlanList[i];
				// Find the item with matching serialNos
				const serialIndex = order.details.findIndex((item) => item.serialNos === serialNo);
				if (serialIndex !== -1) {
					// Update ScanQty
					order.ScanQty -= 1; // Assuming ScanQty needs to be decremented by 1
					// Remove the serialNos
					order.details.splice(serialIndex, 1);
					this.calculateTotal();
					// If ScanQty reaches 0, remove the entire product
					if (order.ScanQty === 0) {
						this.ordersPlanList.splice(i, 1);
					}

					this.ref.markForCheck();
					this.ref.detectChanges();
					break; // Exit loop after updating ScanQty and removing the item
				}
			}
		});

		// You can handle dialog events here if needed
		dialogRef.afterClosed().subscribe((result) => {
			console.log("Dialog closed with result:", result);
		});
	}

	validateBarcodeOLD() {
		const owner_company_id = this.form.value.customerId;
		const barcodeToValidate = this.form.get("scannedBarcode").value;

		const scannedBarcode = this.form.get("scannedBarcode").value; // Assuming scannedBarcode contains "3-22.12.003065"
		//const parts = scannedBarcode.split(".");

		const parts = scannedBarcode.split(/[-.]/);

		if (parts.length >= 3) {
			const extractedValue = parts[0];
			this.coffeeService.DispatchPlanDetailsBarcodScanner(extractedValue, owner_company_id, parts[3]).subscribe(
				(res: any) => {
					console.log("Barcode LOG:", res);
					if (res && res[0]) {
						this.form.patchValue({
							customerId: res[0].owner_company_id,
							scannedBarcode: barcodeToValidate,
							ProductName: res[0].ProductName,
							PlanQty: res[0].ScanQty,
							item_price: res[0].item_price,
						});

						const dispatchPlanIdToMatch = res[0].DispatchPlanDetailsId;
						const scanQtyToAdd = res[0].ScanQty;

						const existingItem = this.ordersPlanList.find((item) => item.DispatchPlanDetailsId === dispatchPlanIdToMatch);

						if (existingItem) {
							// If an item with the matching DispatchPlanDetailsId is found
							const newScanQty = existingItem.ScanQty + scanQtyToAdd;
							if (newScanQty <= existingItem.PlanQty) {
								if (existingItem.serialNos.includes(parts[3])) {
									this.snackBar.open("SerialNo is already added.", "OK", {
										duration: 3000,
									});
								} else {
									existingItem.ScanQty = newScanQty;
									existingItem.serialNos.push(parts[3]);
								}
							} else {
								this.snackBar.open("Adding Scan Qty would exceed PlanQty", "OK", {
									duration: 3000,
								});
							}
						} else {
							this.ordersPlanList.push({
								customerId: res[0].owner_company_id,
								order_no: res[0].order_no,
								order_date: res[0].order_date_formatted,
								order_qty: res[0].order_qty,
								scannedBarcode: barcodeToValidate,
								ProductName: res[0].ProductName,
								PlanQty: res[0].PlanQty,
								item_price: res[0].item_price,
								serialNos: [parts[3]],
								serailDate: parts[1] + "-" + parts[2],
								order_id: res[0].order_id,
								orders_details_id: res[0].Orders_details_id,
								DispatchPlanDetailsId: res[0].DispatchPlanDetailsId,
								ProductId: res[0].ProductId,
								ImsMappingId: res[0].ImsMappingId,
								ScanQty: scanQtyToAdd <= res[0].PlanQty ? scanQtyToAdd : res[0].PlanQty,
							});
						}
						this.calculateTotal();
						this.ref.markForCheck();
						this.ref.detectChanges();
					} else {
						this.snackBar.open("Barcode is not associated with Customer or Product not found", "OK", {
							duration: 3000,
						});
					}
				},
				(error: any) => {
					this.snackBar.open(error.error.message, "OK", {
						duration: 3000,
					});
				}
			);
		} else {
			this.snackBar.open("Invalid scanned Barcode format", "OK", {
				duration: 3000,
			});
		}
	}

	playSound(sound: string) {
		const audioPlayer: HTMLAudioElement = this.audioElement.nativeElement;
		audioPlayer.src = `assets/sounds/${sound}`;
		audioPlayer.load();
		audioPlayer.play();
	}
	ngOnInit() {
		this.form = this.fb.group({
			customerId: ["", Validators.required],
			scannedBarcode: ["", Validators.required],
			ProductName: ["", Validators.required],
			PlanQty: ["", Validators.required],
			item_price: ["", Validators.required],
		});

		this.form.get("customerId").valueChanges.subscribe((value) => {
			// Do something with the changed value
			console.log("customerId value changed:", value);

			this.coffeeService.OwnersCompayGetById(value).subscribe(
				(res: any) => {
					//console.log("LOG:", res);
					this.companyData = res[0];

					this.ref.markForCheck();
					this.ref.detectChanges();
				},
				(error: any) => {
					this.snackBar.open(error.error.message, "OK", {
						duration: 3000,
					});
				}
			);
		});
	}

	submitForm() {
		this.submitted = true;
		this.loading = true;
		const dispatchData = {
			UserId: 0,
			dispatchId: 0,
			email: this.companyData.EmailId,
			TotalAmount: Number.parseFloat(this.total.toFixed(2)),
			owner_company_id: parseInt(this.form.value.customerId, 10),
			DispatchRequest: this.ordersPlanList,
		};

		this.coffeeService.DispatchInsert(dispatchData).subscribe(
			(res: any) => {
				if (res.error === false) {
					this.snackBar.open("Error occurred while saving", "OK", {
						duration: 3000,
					});
				} else {
					this.snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
					this._router.navigate(["/coffee/dispatch-plan-data"]);
				}
			},
			(error: any) => {
				this.snackBar.open(error.error.message, "OK", {
					duration: 3000,
				});
			}
		);
	}
}
