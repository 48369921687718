import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { CoffeeService } from "../../coffee.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "kt-adjust-stock",
	templateUrl: "./adjust-stock.component.html",
	styleUrls: ["./adjust-stock.component.scss"],
})
export class AdjustStockComponent implements OnInit {
	stockForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	loading = false;
	ordersList$: Observable<any[]>;
	private ordersListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	order_no: String;
	order_date: String;
	order_id: String;
	orderValue: String;
	company_name: String;
	order_status: String;

	ProductData: any;

	constructor(public dialogRef: MatDialogRef<AdjustStockComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar, private fb: FormBuilder, private coffeeService: CoffeeService, private spinner: NgxSpinnerService) {
		console.log("Received Order ID:", this.data);
		this.ProductData = this.data;
	}

	ngOnInit() {
		this.createForm();
		this.patchFormValues();
	}

	createForm() {
		this.stockForm = this.fb.group({
			ProductId: ["", Validators.required],
			productName: [" ", Validators.required],
			CategoryName: ["", Validators.required],
			StockQuantity: ["", Validators.required],
			Quantity: ["", [Validators.required, Validators.pattern(/^[0-9]+$/)]],
			TransactionType: ["", Validators.compose([Validators.required])],
		});
	}

	patchFormValues() {
		this.stockForm.patchValue({
			ProductId: this.ProductData.orderId.ProductId,
			productName: this.ProductData.orderId.ProductName,
			CategoryName: this.ProductData.orderId.CategoryName,
			StockQuantity: this.ProductData.orderId.StockQuantity,
			// Add other form controls here as needed
		});
	}

	getTitle(): string {
		return "Adjust Stock";
	}

	onSubmit() {
		if (this.stockForm.valid) {
			const formData = { ...this.stockForm.value };

			this.coffeeService.InventoryTransactionsInsert(formData).subscribe(
				(res: any) => {
					if (res.error === false) {
						this._snackBar.open("Error occurred while saving", "OK", {
							duration: 3000,
						});
					} else {
						this._snackBar.open("Saved Successfully", "Undo", {
							duration: 3000,
						});

						this.dialogRef.close();
					}
				},
				(error: any) => {
					this._snackBar.open(error.error, "OK", {
						duration: 3000,
					});
					this.loading = false;
				}
			);
		} else {
			// Mark all form controls as touched to display error messages
			this.markFormGroupTouched(this.stockForm);
		}
	}

	markFormGroupTouched(formGroup: FormGroup) {
		Object.values(formGroup.controls).forEach((control) => {
			control.markAsTouched();

			if (control instanceof FormGroup) {
				this.markFormGroupTouched(control);
			}
		});
	}

	integerMaxFiveDigitsValidator(control: AbstractControl): ValidationErrors | null {
		const value = control.value;

		// Check if the value is an integer with a maximum of 5 digits
		if (!Number.isInteger(value) || value < 0 || value > 99999) {
			return { invalidInteger: true };
		}

		return null;
	}
}
