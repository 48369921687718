import { ChangeDetectorRef, Component, ElementRef, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormArray, Validators, FormGroup, AbstractControl, ValidatorFn, FormControl } from "@angular/forms";
import { Event, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Router } from "@angular/router";
import { Observable, Subject, Subscription, of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../src/environments/environment";
import { CoffeeService } from "../coffee.service";
import { Select2OptionData } from "ng-select2";
import { SharedDataService } from "../shared-data.service";
import { debounceTime, delay, distinctUntilChanged, startWith, switchMap } from "rxjs/operators";

const DEMO_PARAMS = {
	EMAIL: "admin@demo.com",
	PASSWORD: "demo",
};

@Component({
	selector: "kt-owners",
	templateUrl: "./owners.component.html",
	styleUrls: ["./owners.component.scss"],
})
export class OwnersComponent implements OnInit {
	@ViewChild("wizard", { static: true }) el: ElementRef;

	ownserOptions$: Observable<any>;
	ownerCompanyForm: FormGroup;
	dropdownOptions$: Observable<any>; // Replace with the actual dropdown options observable
	stateOptions$: Observable<any>; // Replace with the actual state options observable
	cityOptions$: Observable<any>; // Replace with the actual city options observable
	managerOptions$: Observable<any>; // Replace with the actual manager options observable
	loading = false;

	ownsersDropdown$: Observable<any>;

	//	selectedOwnerId: any;
	selectOwner: any[] = [];
	selectOptions: any = {};
	selectOptionsConfig: any = {};

	stateData: any[] = [];
	stateOptions: any = {};
	stateOptionsConfig: any = {};
	options = [
		{ id: "1", text: "Option 1" },
		{ id: "2", text: "Option 2" },
		{ id: "3", text: "Option 3" },
	];
	selectedValue: any;
	selectedOwnerId: any;
	cityData: any[] = [];
	cityOptions: any = {};
	cityOptionsConfig: any = {};
	isSameAsAbove: boolean = false;

	public DepartmentId: string = "0";
	userData: any[] = [];
	userOptions: any = {};
	userOptionsConfig: any = {};
	selectOptionsConfig$: Observable<any>;
	selectOptionsConfig2: any = {};
	accountData: any[] = [];
	accountOptions: any = {};
	accountOptionsConfig: any = {};

	priceData: any[] = [];
	priceOptions: any = {};
	priceOptionsConfig: any = {};

	CurrentRoleId: string;
	stateDropdown$: Observable<any>;
	cityDropdown$: Observable<any[]>;
	private cityDropdownSubject = new Subject<any[]>();

	cityDropdownShipping$: Observable<any[]>;
	private cityDropdownShippingSubject = new Subject<any[]>();

	step1CompanyInfoForm: FormGroup;
	step2AccountInfoForm: FormGroup;
	step3AddressInfoForm: FormGroup;

	currentStep = 1;
	private subscription: Subscription;

	exampleData$: Observable<Select2OptionData>;

	startValue$: Observable<string>;

	searchInput: string;
	data$: Observable<any[]>;

	searchManager: string;
	Managerdata$: Observable<any[]>;

	searchAccountant: string;
	Accountantdata$: Observable<any[]>;

	searchPricing: string;
	PricingData$: Observable<any[]>;

	constructor(private formBuilder: FormBuilder, private sharedDataService: SharedDataService, private coffeeservice: CoffeeService, private _snackBar: MatSnackBar, private cdRef: ChangeDetectorRef, private router: Router) {
		this.cityDropdown$ = this.cityDropdownSubject.asObservable();
		this.cityDropdownShipping$ = this.cityDropdownShippingSubject.asObservable();
	}

	forms() {
		this.step1CompanyInfoForm = this.formBuilder.group({
			owner_company_id: ["0"],
			owner_id: ["", Validators.required],
			company_name: ["", Validators.required],
			phone: ["", [Validators.required, phoneValidator]],
			email: ["", emailValidator],
			panno: ["", Validators.required],
			gstno: ["", Validators.required],
			website: ["", [websiteValidator()]],
			user_id: ["0"],
			isActive:[true],
		});

		// Initialize the FormGroup for step 2
		this.step2AccountInfoForm = this.formBuilder.group({
			manager_id: ["", Validators.required],
			account_id: ["", Validators.required],
			productsPricingId: ["", Validators.required],
		});

		// Initialize the FormGroup for step 3
		this.step3AddressInfoForm = this.formBuilder.group({
			address: ["", Validators.required],
			address2: ["", Validators.required],
			state_id: ["", Validators.required],
			city_id: ["", Validators.required],
			pincode: ["", [Validators.required, Validators.pattern("^[0-9]+$")]],
			isSameAsAbove: [false],
			shpippingaddress: ["", Validators.required],
			shpippingaddress2: ["", Validators.required],
			shippingState: ["", Validators.required],
			shippingCity: ["", Validators.required],
			shippingPincode: ["", [Validators.required, Validators.pattern("^[0-9]+$")]],
		});

		this.step3AddressInfoForm.get("state_id").valueChanges.subscribe((value) => {
			console.log(value);
			this.CityDropdownPopulate(value);
		});

		this.step3AddressInfoForm.get("shippingState").valueChanges.subscribe((value) => {
			console.log(value);
			this.CityDropdownShippingPopulate(value);
		});
	}

	EditeMode() {
		this.subscription = this.sharedDataService.companyOwnerData$.subscribe((data) => {
			var obj = JSON.parse(data);

			this.step1CompanyInfoForm.controls.owner_company_id.setValue(obj.owner_company_id.toString());
			this.step1CompanyInfoForm.controls.owner_id.setValue(obj.owner_id.toString());

			console.log(obj.owner_id.toString());

			this.step1CompanyInfoForm.controls.company_name.setValue(obj.company_name.toString());
			this.step1CompanyInfoForm.controls.phone.setValue(obj.phone.toString());
			this.step1CompanyInfoForm.controls.email.setValue(obj.EmailId.toString());
			this.step1CompanyInfoForm.controls.panno.setValue(obj.panno.toString());
			this.step1CompanyInfoForm.controls.gstno.setValue(obj.gstno.toString());
			this.step1CompanyInfoForm.controls.website.setValue(obj.website.toString());
			this.step1CompanyInfoForm.controls.isActive.setValue(obj.isActive);

			this.step2AccountInfoForm.controls.manager_id.setValue(obj.manager_id.toString());
			this.step2AccountInfoForm.controls.account_id.setValue(obj.account_id.toString());
			this.step2AccountInfoForm.controls.productsPricingId.setValue(obj.productsPricingId.toString());

			this.step3AddressInfoForm.controls.address.setValue(obj.address.toString());
			this.step3AddressInfoForm.controls.address2.setValue(obj.shpippingaddress2.toString());
			this.step3AddressInfoForm.controls.state_id.setValue(obj.state_id.toString());
			this.step3AddressInfoForm.controls.city_id.setValue(obj.city_id.toString());
			this.step3AddressInfoForm.controls.pincode.setValue(obj.pincode.toString());
			this.step3AddressInfoForm.controls.isSameAsAbove.setValue(obj.isSameAsAbove);
			this.step3AddressInfoForm.controls.shpippingaddress.setValue(obj.shpippingaddress.toString());
			this.step3AddressInfoForm.controls.shpippingaddress2.setValue(obj.shpippingaddress2.toString());
			this.step3AddressInfoForm.controls.shippingState.setValue(obj.shippingState.toString());
			this.step3AddressInfoForm.controls.shippingCity.setValue(obj.shippingCity.toString());
			this.step3AddressInfoForm.controls.shippingPincode.setValue(obj.shippingPincode.toString());
		});
		this.sharedDataService.clearCompanyOwnerData();
	}

	setupSearch() {
		//Owner
		this.data$ = new Observable<string>((observer) => {
			observer.next(this.searchInput);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeservice.dropdownAll(query, "1", "1", "0");
			})
		);

		//Manager

		this.Managerdata$ = new Observable<string>((observer) => {
			observer.next(this.searchManager);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeservice.dropdownAll(query, "1", "14", "0");
			})
		);

		//Accountant

		this.Accountantdata$ = new Observable<string>((observer) => {
			observer.next(this.searchAccountant);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeservice.dropdownAll(query, "1", "14", "0");
			})
		);

		//Pricing

		this.PricingData$ = new Observable<string>((observer) => {
			observer.next(this.searchPricing);
		}).pipe(
			startWith(""),
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((query: string) => {
				console.log("Search Query:", query);
				query = query || "";

				return this.coffeeservice.dropdownAll(query, "1", "6", "0");
			})
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	ngOnInit() {
		this.statedropdown();

		this.forms();

		this.setupSearch();

		this.EditeMode();
	}
	change() {
		this.selectedValue = "event";
	}
	onValueChanged(event: any) {
		this.selectedValue = event;
		console.log("Selected Value:", this.selectedValue);
	}
	public handleChange(value: any): void {
		debugger;
		console.log("Selected value is: ", value);
	}
	copyAddress() {
		if (this.step3AddressInfoForm.get("isSameAsAbove").value) {
			const mainAddress = this.step3AddressInfoForm.value;

			// Copy data from main address to shipping address
			this.step3AddressInfoForm.patchValue({
				shpippingaddress: mainAddress.address,
				shpippingaddress2: mainAddress.address2,
				shippingState: mainAddress.state_id,
				shippingCity: mainAddress.city_id,
				shippingPincode: mainAddress.pincode,
			});
		} else {
			// Clear the shipping address fields if the checkbox is unchecked
			this.step3AddressInfoForm.patchValue({
				shpippingaddress: "",
				shpippingaddress2: "",
				shippingState: "",
				shippingCity: "",
				shippingPincode: "",
			});
		}

		this.cdRef.markForCheck();
		this.cdRef.detectChanges();
	}

	statedropdown() {
		this.stateDropdown$ = this.coffeeservice.dropdownAll("", "1", "2", "0");
	}

	onSubmit() {
		this.loading = true;
		

		if (this.step1CompanyInfoForm.valid && this.step2AccountInfoForm.valid && this.step3AddressInfoForm.valid) {
			const formData = {
				...this.step1CompanyInfoForm.value,
				...this.step2AccountInfoForm.value,
				...this.step3AddressInfoForm.value,
			};

			this.coffeeservice.OwnercompanyInsertUpdate(formData).subscribe((res: any) => {
				if (res.error == false) {
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
					});
				} else {
					this._snackBar.open("Saved Successfully", "Undo", {
						duration: 3000,
						
					});
					this.router.navigate(["/coffee/ownsers-data"]);
				}
				this.ownerCompanyForm.reset();
				// this.router.navigate(["/coffee/sitesdata"]);
				//this.router.navigate(["/coffee/ownsers-data"]);
			});
		} else {
			this._snackBar.open("Invalid form value", "Okay", {
				duration: 3000,
			});

			this.loading = false;
			
			return;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log("changed value is ");
		if (changes.ownerCompanyForm && changes.ownerCompanyForm.currentValue) {
			this.serverSideLogic();
		}
	}

	serverSideLogic() {
		console.log("changed value is ");
	}

	stateChanged(event: any) {
		console.log(event);
		console.log("changed value is ");
		var userdata = JSON.parse(localStorage.getItem("user"));
		this.cityOptionsConfig = {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 3,
						parentId: event,
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a State",
			minimumInputLength: 0,
		};
	}

	cityChanged(event: any) {
		// Handle city change event
	}

	managerChanged(event: any) {
		// Handle manager change event
	}
	accouuntChanged(event: any) {
		// Handle manager change event
	}
	ownserChanged(event: any) {
		// Handle manager change event
	}

	ngAfterViewInit(): void {
		// Initialize form wizard
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1,
		});

		wizard.on("beforeNext", (wizardObj) => {
			const currentStepIndex = wizard.getStep();

			if (!this.validateStep(currentStepIndex)) {
				this._snackBar.open("Please fill in all required fields.", "OK", {
					duration: 3000,
				});
				wizardObj.stop();
			} else {
				this.currentStep++; // Move to the next step
			}
		});

		// Change event
		wizard.on("change", () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});

		// Get the current step index
	}

	CityDropdownPopulate(StateId) {
		this.coffeeservice.dropdownAll("", "1", "3", StateId).subscribe((data) => {
			this.cityDropdownSubject.next(data);
		});
	}

	CityDropdownShippingPopulate(StateId) {
		this.coffeeservice.dropdownAll("", "1", "3", StateId).subscribe((data) => {
			this.cityDropdownShippingSubject.next(data);
		});
	}

	validateStep(step: number): boolean {
		switch (step) {
			case 1: {
				if (this.step1CompanyInfoForm.invalid) {
					Object.values(this.step1CompanyInfoForm.controls).forEach((control) => control.markAsTouched());
					this.cdRef.markForCheck();
					this.cdRef.detectChanges();
					return false;
				} else {
					return true;
				}
			}

			case 2: {
				if (this.step2AccountInfoForm.invalid) {
					Object.values(this.step2AccountInfoForm.controls).forEach((control) => control.markAsTouched());
					this.cdRef.markForCheck();
					this.cdRef.detectChanges();
					return false;
				} else {
					return true;
				}
			}

			case 3: {
				if (this.step3AddressInfoForm.invalid) {
					Object.values(this.step3AddressInfoForm.controls).forEach((control) => control.markAsTouched());
					this.cdRef.markForCheck();
					this.cdRef.detectChanges();
					return false;
				} else {
					return true;
				}
			}

			default:
				return false;
		}
	}

	private getSelectOptionsConfigOl(): Observable<any> {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return of({
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 1,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const initialOwnerId = 30;

					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		});
	}

	AllDropDowns() {
		var userdata = JSON.parse(localStorage.getItem("user"));

		// this.selectOptionsConfig = {
		// 	ajax: {
		// 		url: environment.BaseUrl + "api/Owner/allDropdown",
		// 		dataType: "json",
		// 		delay: 250,
		// 		headers: {
		// 			Authorization: "Bearer " + userdata.token,
		// 		},
		// 		data: (params: any) => {
		// 			return {
		// 				searchTerm: params.term,
		// 				page: params.page,
		// 				type: 1,
		// 				parentId: "0",
		// 			};
		// 		},
		// 		processResults: (data: any) => {
		// 			this.loading = false;
		// 			const results = data.map((item: any) => {
		// 				return {
		// 					id: item.id,
		// 					text: item.text,
		// 				};
		// 			});

		// 			return {
		// 				results: results,
		// 			};
		// 		},
		// 		cache: true,
		// 	},
		// 	placeholder: "Select a Owners",
		// 	minimumInputLength: 0,
		// };

		// this.stateOptionsConfig = {
		// 	ajax: {
		// 		url: environment.BaseUrl + "api/Owner/allDropdown",
		// 		dataType: "json",
		// 		delay: 250,
		// 		headers: {
		// 			Authorization: "Bearer " + userdata.token,
		// 		},
		// 		data: (params: any) => {
		// 			return {
		// 				searchTerm: params.term,
		// 				page: params.page,
		// 				type: 2,
		// 				parentId: "0",
		// 			};
		// 		},
		// 		processResults: (data: any) => {
		// 			this.loading = false;
		// 			const results = data.map((item: any) => {
		// 				return {
		// 					id: item.id,
		// 					text: item.text,
		// 				};
		// 			});

		// 			return {
		// 				results: results,
		// 			};
		// 		},
		// 		cache: true,
		// 	},
		// 	placeholder: "Select a State",
		// 	minimumInputLength: 0,
		// };

		// this.userOptionsConfig = {
		// 	ajax: {
		// 		url: environment.BaseUrl + "api/Owner/allDropdown",
		// 		dataType: "json",
		// 		delay: 250,
		// 		headers: {
		// 			Authorization: "Bearer " + userdata.token,
		// 		},
		// 		data: (params: any) => {
		// 			return {
		// 				searchTerm: params.term,
		// 				page: params.page,
		// 				type: 14,
		// 				parentId: "0",
		// 			};
		// 		},
		// 		processResults: (data: any) => {
		// 			this.loading = false;
		// 			const results = data.map((item: any) => {
		// 				return {
		// 					id: item.id,
		// 					text: item.text,
		// 				};
		// 			});

		// 			return {
		// 				results: results,
		// 			};
		// 		},
		// 		cache: true,
		// 	},
		// 	placeholder: "Select a Manager",
		// 	minimumInputLength: 0,
		// };

		// this.accountOptionsConfig = {
		// 	ajax: {
		// 		url: environment.BaseUrl + "api/Owner/allDropdown",
		// 		dataType: "json",
		// 		delay: 250,
		// 		headers: {
		// 			Authorization: "Bearer " + userdata.token,
		// 		},
		// 		data: (params: any) => {
		// 			return {
		// 				searchTerm: params.term,
		// 				page: params.page,
		// 				type: 13,
		// 				parentId: "0",
		// 			};
		// 		},
		// 		processResults: (data: any) => {
		// 			this.loading = false;
		// 			const results = data.map((item: any) => {
		// 				return {
		// 					id: item.id,
		// 					text: item.text,
		// 				};
		// 			});

		// 			return {
		// 				results: results,
		// 			};
		// 		},
		// 		cache: true,
		// 	},
		// 	placeholder: "Select a Accountant",
		// 	minimumInputLength: 0,
		// };

		// this.priceOptionsConfig = {
		// 	ajax: {
		// 		url: environment.BaseUrl + "api/Owner/allDropdown",
		// 		dataType: "json",
		// 		delay: 250,
		// 		headers: {
		// 			Authorization: "Bearer " + userdata.token,
		// 		},
		// 		data: (params: any) => {
		// 			return {
		// 				searchTerm: params.term,
		// 				page: params.page,
		// 				type: 6,
		// 				parentId: "0",
		// 			};
		// 		},
		// 		processResults: (data: any) => {
		// 			this.loading = false;
		// 			const results = data.map((item: any) => {
		// 				return {
		// 					id: item.id,
		// 					text: item.text,
		// 				};
		// 			});

		// 			return {
		// 				results: results,
		// 			};
		// 		},
		// 		cache: true,
		// 	},
		// 	placeholder: "Select a Pricing",
		// 	minimumInputLength: 0,
		// };

		// this.selectedOwnerId = "30";

		this.cdRef.detectChanges();
	}

	getSelectOptionsConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 1,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Owners",
			minimumInputLength: 0,
		};
	}

	getManagersConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 14,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Manager",
			minimumInputLength: 0,
		};
	}

	getAccountantConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 14,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Accountant",
			minimumInputLength: 0,
		};
	}

	getPricingConfig(): any {
		var userdata = JSON.parse(localStorage.getItem("user"));
		return {
			ajax: {
				url: environment.BaseUrl + "api/Owner/allDropdown",
				dataType: "json",
				delay: 250,
				headers: {
					Authorization: "Bearer " + userdata.token,
				},
				data: (params: any) => {
					return {
						searchTerm: params.term,
						page: params.page,
						type: 6,
						parentId: "0",
					};
				},
				processResults: (data: any) => {
					this.loading = false;
					const results = data.map((item: any) => {
						return {
							id: item.id,
							text: item.text,
						};
					});

					return {
						results: results,
					};
				},
				cache: true,
			},
			placeholder: "Select a Pricing",
			minimumInputLength: 0,
		};
	}
}
function emailValidator(control: AbstractControl): { [key: string]: any } | null {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	const email = control.value;

	if (email && !emailPattern.test(email)) {
		return { invalidEmail: true };
	}

	return null;
}

function phoneValidator(control: AbstractControl): { [key: string]: any } | null {
	// Define a regular expression for a valid mobile phone number
	const phonePattern = /^[0-9]{10}$/; // You can adjust this pattern to match your specific requirements

	const phone = control.value;

	if (phone && !phonePattern.test(phone)) {
		return { invalidPhone: true };
	}

	return null;
}
// Regular expression pattern to validate website URLs

const URL_PATTERN = /^(https:\/\/www\.[^\s/$.?#].[^\s]*)$/i;
export function websiteValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const url = control.value;

		if (!url) {
			// If the control is empty, consider it valid (you can change this behavior)
			return null;
		}

		if (URL_PATTERN.test(url)) {
			return null; // Valid URL
		} else {
			return { invalidWebsite: true }; // Invalid URL
		}
	};
}
